import * as XLSX from "xlsx";
import React from "react";

export function downloadTemplate(
  headers = ["adm_no", "year_awarded", "amount_awarded", "award_code"],
  fileName = "import_template.xlsx"
) {
  const wb = XLSX.utils.book_new();

  let sheet1 = [];

  sheet1.push(headers);

  const ws = XLSX.utils.aoa_to_sheet(sheet1);
  XLSX.utils.book_append_sheet(wb, ws, "Template");

  //Export
  XLSX.writeFile(wb, fileName);
}

/**
 * Checks if the start date is later than the end date.
 *
 * @param {string} startDate - The start date in a format recognized by the Date constructor (e.g., "YYYY-MM-DD").
 * @param {string} endDate - The end date in a format recognized by the Date constructor (e.g., "YYYY-MM-DD").
 * @returns {boolean} - Returns true if the start date is later than the end date, otherwise false.
 */
export const isStartDateLaterThanEndDate = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Compare the dates
  return start > end;
};

export const createUrlWithParams = (baseUrl, params) => {
  // Check if the base URL already contains a '?'
  const hasQueryParams = baseUrl.includes("?");
  const url = new URL(baseUrl);

  // Create URLSearchParams object from the existing query string if it exists
  const searchParams = new URLSearchParams(url.search);

  // Append the new params to the existing searchParams
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      searchParams.set(key, (params[key])); // Use 'set' to override if the key exists, or add if it doesn't
    }
  }


  url.search = searchParams.toString().trim().replace(/\+/g, ' ');

  // If baseUrl has no query params, append a '?', otherwise append '&'
  // url.search = searchParams.toString();

  return url.toString();
};


export const validateNRICFIN = (inputText) => {
    // validation rules
    const nricRegex = /(\D)(\d{7})(\D)/;
    const nricTypeRegex = /S|T|F|G/;
    const weightArr = [2, 7, 6, 5, 4, 3, 2];
    const nricLetterST = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
    const nricLetterFG = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
  
    // set nric to all uppercase to remove case sensitivity
    const nric = inputText.toUpperCase();
  
    // returns false if it false basic validation
    if (!nricRegex.exec(nric)) {
      return false;
    }
  
    // get nric type
    const nricArr = nric.match(nricRegex);
    const nricType = nricArr[1];
  
    // returns false if it false basic validation
    if (!nricTypeRegex.exec(nricType)) {
      return false;
    }
  
    // multiply each digit in the nric number by it's weight in order
    const nricDigitsArr = nricArr[2].split('');
    let total = 0;
    for (let i = 0; i < nricDigitsArr.length; i++) {
      total += parseInt(nricDigitsArr[i]) * weightArr[i] ;
    }
  
    // if the nric type is T or G, add 4 to the total
    if (['T', 'G'].indexOf(nricType) >= 0) {
      total += 4;
    }
  
    // check last letter of nric for local
    const letterIndex = total % 11;
    const nricLetter = nricArr[3];
    if (['S', 'T'].indexOf(nricType) >= 0) {
      return nricLetterST[letterIndex] === nricLetter;
    }
  
    // check last letter of nric for foreigners
    return nricLetterFG[letterIndex] === nricLetter;
  
      
}

export const sortExerciseList = (exerciseList) => {
  return exerciseList?.sort((a,b) => {
    return parseInt(a?.exercise, 10) - parseInt(b?.exercise, 10)
  })
}

export const sortDescendingByKey = (list, keyLabel) => {
  return list?.sort((a,b) => {
    return parseInt(b[keyLabel], 10) - parseInt(a[keyLabel], 10)
  })
}