import { apexAxiosCall, apiCall } from "@/utils/apiCall";

// {
//     "ACAD_YR": "2024",
//     "EXERCISE": "6",
//     "START_DATE": "2024-10-01 12:00:00",
//     "START_TIME": "1200",
//     "END_DATE": "2024-11-01 12:00:00",
//     "END_TIME": "1200",
//     "CUTOFF_INCOME": "1000",
//     "MPCI_INCOME": "2000",
//     "MULTIPLE_AWARD_PER_AY": "Y",
//     "REMARK": "this is remark description",
//     "VSTATUS_END_DATE": null,
//     "ALLOW_SPONSOR_TAG": null,
//     "ATTACHMENT1": null,
//     "ATTACHMENT2": null,
//     "ATTACHMENT3": null,
//     "SGCITIZEN_TAG": null,
//     "SGPR_TAG": "Y",
//     "NONSG_PR_TAG": null,
//     "FASOP_TAG": "Y",
//     "HOMES_TAG": "Y",
//     "ADM_TAG": null
// }

export async function getTimeline(acadYear, exercise, isNested = false) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines?acad_yr=${acadYear}&exercise=${exercise}&isNested=${isNested}`,
    {},
    "GET"
  );
}

export function postTimeline(timelineData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines`,
    timelineData,
    "POST"
  );
}

export function postCheckOverlapTimeline(timelineData){
    return apiCall(
        `${process.env.REACT_APP_API_URL}/applications/timelines/checks/overlaps`,
        timelineData,
        "POST"
    );
}

export function putTimeline(timelineID, timelineData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines/${timelineID}`,
    timelineData,
    "PUT"
  );
}

export function deleteTimeline(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines/${selectedId}`,
    {},
    "DELETE"
  );
}

export function getStudentList(search) {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/getStudentList",
    {
      studstatus: "",
      transfer_tag: "N",
      page: "1",
      limit: "20",
      search: search,
      sort: "id_no",
    }
  );
}

export function getTimelineDT(acadYear, exercise) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines-dts?acad_yr=${acadYear}&exercise=${exercise}`,
    {},
    "GET"
  );
}

export function deleteTimelineDT(id) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines-dts/${id}`,
    {},
    "DELETE"
  );
}

export function getTimelineADM(acadYear, exercise) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines-adms?acad_yr=${acadYear}&exercise=${exercise}`,
    {},
    "GET"
  );
}

export function deleteTimelineADM(id) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines-adms/${id}`,
    {},
    "DELETE"
  );
}
