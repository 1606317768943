import {Button, Col, Row} from "@govtechsg/sgds-react";
import {Link} from "react-router-dom";

export default function RemarksMenu({activeMenu}) {
    const listMenu = [
        {
            url: "/preparation/remarks/pa",
            label: "Status Remark (PA)",
            name: "pa_remarks"
        },
        {
            url: "/preparation/remarks/nyp",
            label: "Status Remark (NYP)",
            name: "nyp_remarks"
        }
    ]
    let activeMenuVariant = 'primary';
    return (
        <>
            <Row>
                <Col md={12}>
                    <div className="d-block  d-lg-flex gap-2 align-item-center wrap">
                        {listMenu.map(item => {
                            return (<Link to={item.url} key={item.name}>
                                <Button
                                    variant={item.name === activeMenu ? activeMenuVariant : ''}
                                    size="sm"
                                    className={`${item.name === activeMenu ? 'active ' : ''}text-uppercase fw-bold rounded-0`}
                                >
                                    {item.label}
                                </Button>
                            </Link>)
                        })}
                    </div>
                </Col>
            </Row>
        </>
    )
}
