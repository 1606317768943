import { Button, Col, Row } from "@govtechsg/sgds-react";
import { Link } from "react-router-dom";

export default function RelationshipMenu({ activeMenu }) {
  const listMenu = [
    {
      url: "/preparation/setup-relationship/dialect",
      label: "Dialect Group",
      name: "dialect",
    },
    {
      url: "/preparation/setup-relationship/marital-status",
      label: "Marital Status",
      name: "marital",
    },
    {
      url: "/preparation/setup-relationship/relationship",
      label: "Relationship",
      name: "relationship",
    },
    {
      url: "/preparation/setup-relationship/housing-type",
      label: "Housing Type",
      name: "housing",
    },
  ];
  let activeMenuVariant = "primary";
  return (
    <>
      <Row>
        <Col md={12}>
          <div className="d-block  d-lg-flex gap-2 align-item-center wrap">
            {listMenu.map((item) => {
              return (
                <Link to={item.url} key={item.name}>
                  <Button
                    variant={item.name === activeMenu ? activeMenuVariant : ""}
                    size="sm"
                    className={`${
                      item.name === activeMenu ? "active " : ""
                    }text-uppercase fw-bold rounded-0`}
                  >
                    {item.label}
                  </Button>
                </Link>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
}
