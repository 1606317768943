import {Button, Modal} from "@govtechsg/sgds-react";
import React from "react"

export default function CustomModal({
                                        title = "",
                                        description = "",
                                        show,
                                        handleClose,
                                        action,
                                        submitButtonText = "Proceed",
                                        modalSize = "lg",
                                        withIcon = true,
                                        hideSubmitButton = false
                                    }) {
    return (
        <Modal size={modalSize} show={show} onHide={handleClose} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {withIcon && <i className="bi bi-exclamation-triangle me-3"></i>}
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{description}</Modal.Body>
            <Modal.Footer className={`gap-2`}>
                <Button variant="outline-dark" onClick={handleClose}>
                    Cancel
                </Button>
                {
                    !hideSubmitButton && (
                        <Button variant="primary" onClick={action}>
                            {submitButtonText}
                        </Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    );
}
