import { apiCall } from "@/utils/apiCall";
import { createUrlWithParams } from "@/components/utils/utils";

// CSCode

// {
//     "CS_CODE": "TB",
//     "CS_NAME": "Tanjong Pagar",
//     "TEL1": null,
//     "TEL2": null,
//     "FAX": null
// }

export async function getCSList(search, limit = 20, page = 1) {
  let params = {};
  if (search !== null && search !== "" && search !== undefined) {
    params = {
      ...params,
      cs_code: search,
    };
  }
  params = {
    ...params,
    limit: limit,
    page: page,
  };

  let baseUrl = `${process.env.REACT_APP_API_URL}/applications/cscodes`;
  const url = createUrlWithParams(baseUrl, params);
  return apiCall(url, {}, "GET");
}

export function postCS(CSData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/cscodes`,
    CSData,
    "POST"
  );
}

export function putCS(CSID, CSData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/cscodes/${CSID}`,
    CSData,
    "PUT"
  );
}

export function deleteCS(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/cscodes/${selectedId}`,
    {},
    "DELETE"
  );
}

export function postAppendCS(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/cscodes/append`,
    {
      data: jsonData,
    },
    "POST"
  );
}

export function postOverwriteCS(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/cscodes/overwrite`,
    {
      data: jsonData,
    },
    "POST"
  );
}

// Postal Code

// {
//     "POSTAL_CODE": "3311",
//     "FAAS_CS_CODES_ID": "a6482c28-6384-4a61-8074-009e96a5381d"
// }

export async function getPCList(search, limit, page) {
  let params = {};
  if (search !== null && search !== "" && search !== undefined) {
    params = {
      ...params,
      postal_code: search,
    };
  }
  params = {
    ...params,
    limit: limit,
    page: page,
  };

  let baseUrl = `${process.env.REACT_APP_API_URL}/applications/postalcodes`;
  const url = createUrlWithParams(baseUrl, params);
  return apiCall(url, {}, "GET");
}

export function postPC(PCData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/postalcodes`,
    PCData,
    "POST"
  );
}

export function putPC(PCID, PCData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/postalcodes/${PCID}`,
    PCData,
    "PUT"
  );
}

export function deletePC(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/postalcodes/${selectedId}`,
    {},
    "DELETE"
  );
}

export function postAppendPC(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/postalcodes/append`,
    {
      data: jsonData,
    },
    "POST"
  );
}

export function postOverwritePC(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/postalcodes/overwrite`,
    {
      data: jsonData,
    },
    "POST"
  );
}
