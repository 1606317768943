import { apiCall } from "@/utils/apiCall";
import { createUrlWithParams } from "@/components/utils/utils";

export async function getDetailCriteria(data) {
  let params = {};
  if (data?.award_type !== null && data?.award_type !== undefined) {
    params = {
      ...params,
      award_type: data?.award_type,
    };
  }
  if (data?.award !== null && data?.award !== undefined) {
    params = {
      ...params,
      award: data?.award,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/criteria/faas`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function postSaveCriteria(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/criteria/faas`,
    params,
    "POST"
  );
}

export async function getCourseCriteria(data) {
  let params = {};
  if (data?.award_type !== null && data?.award_type !== undefined) {
    params = {
      ...params,
      award_type: data?.award_type,
    };
  }
  if (data?.award !== null && data?.award !== undefined) {
    params = {
      ...params,
      award: data?.award,
    };
  }

  let baseUrl =
    process.env.REACT_APP_API_URL + `/applications/criteria/courses`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function getCitizenshipCriteria(data) {
  let params = {};
  if (data?.award_type !== null && data?.award_type !== undefined) {
    params = {
      ...params,
      award_type: data?.award_type,
    };
  }
  if (data?.award !== null && data?.award !== undefined) {
    params = {
      ...params,
      award: data?.award,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/citizenships`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function getCWACriteria(data) {
  let params = {};
  if (data?.award_type !== null && data?.award_type !== undefined) {
    params = {
      ...params,
      award_type: data?.award_type,
    };
  }
  if (data?.award !== null && data?.award !== undefined) {
    params = {
      ...params,
      award: data?.award,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/cwas`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function getGPACriteria(data) {
  let params = {};
  if (data?.award_type !== null && data?.award_type !== undefined) {
    params = {
      ...params,
      award_type: data?.award_type,
    };
  }
  if (data?.award !== null && data?.award !== undefined) {
    params = {
      ...params,
      award: data?.award,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/gpas`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function getExcludedAwardCriteria(data) {
  let params = {};
  if (data?.award_type !== null && data?.award_type !== undefined) {
    params = {
      ...params,
      award_type: data?.award_type,
    };
  }
  if (data?.award !== null && data?.award !== undefined) {
    params = {
      ...params,
      award: data?.award,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/criteria/awards`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function deleteCourseCriteria(id) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/criteria/courses/${id}`,
    {},
    "DELETE"
  );
}

export async function deleteCitizenshipCriteria(id) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/citizenships/${id}`,
    {},
    "DELETE"
  );
}

export async function deleteCWACriteria(id) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/cwas/${id}`,
    {},
    "DELETE"
  );
}

export async function deleteGPACriteria(id) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/gpas/${id}`,
    {},
    "DELETE"
  );
}

export async function deleteExcludedAwardCriteria(id) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/criteria/awards/${id}`,
    {},
    "DELETE"
  );
}
