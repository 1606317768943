import React, {useCallback, useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {Button, Col, Form, FormCheck, Row, Table,} from "@govtechsg/sgds-react";
import Select from "react-select";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {getAcademyYear, getExercise} from "@/apis/timeline/api";
import {getApplicantSummary, GetApplications} from "@/apis/reports/api";
import CustomModal from "@/components/CustomModal";
import jsPDF from "jspdf";
import {utils, writeFileXLSX} from "xlsx";
import {toast} from "react-toastify";
import {getCodeTypeSasList} from "@/apis/sims/api";
import {getAwardListWithParams} from "@/apis/preparation/setup-award/api";
import {sortExerciseList, sortDescendingByKey} from "@/components/utils/utils";

function PrintApplicationSummary() {
    const refPDF = useRef();
    const refCSV = useRef();
    const headerRef = useRef();
    const reportDataRef = useRef();
    const table1Ref = useRef()
    const table2Ref = useRef()
    const table3Ref = useRef()
    const table4Ref = useRef()
    const table5Ref = useRef()
    const tbl = useRef(null);
    const [showRetrieve, setShowRetrieve] = useState(false);
    const [showModalExport, setShowModalExport] = useState(false);

    const [dataExercise, setDataExercise] = useState([]);
    const [academyYear, setAcademyYear] = useState(null);
    const [exercise, setExercise] = useState(null);
    const [dataAcademyYear, setDataAcademyYear] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loadingAcademyYear, setLoadingAcademyYear] = useState(true);

    const [applicantSummary, setApplicantSummary] = useState({});
    const [totalApplicant, setTotalApplicant] = useState(0);

    const [disbursed, setDisbursed] = useState([]);
    const [excluded, setExcluded] = useState([]);
    const [unsuccessful, setUnsuccessful] = useState([]);

    const [awardTypeList, setAwardTypeList] = useState([]);
    const [awardCodeList, setAwardCodeList] = useState([]);

    useEffect(() => {
        handleGetListAcademyYear();
        handleGetListAwardType();
    }, []);
    useEffect(() => {
        if (academyYear?.value) handleGetListExercise();
    }, [academyYear]);

    const handleGetListExercise = async () => {
        const params = {
            acad_yr: academyYear?.value,
        };

        try {
            setExercise(null);

            const response = await getExercise(params);

            if (response?.data?.data?.length > 0) {
                setDataExercise(
                    sortExerciseList(response?.data?.data)?.map((item) => ({
                        label: item?.exercise,
                        value: item?.exercise,
                    }))
                );

            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const getQuantumValue = async (awardType, award) => {
        return getAwardListWithParams(awardType, award).then((resp) => {
            return resp?.data?.data[0]?.quantum;
        });
    };

    const handleRetrieve = () => {
        setShowRetrieve(true);

        // get all applicant summary
        getApplicantSummary(academyYear?.value, exercise?.value).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        const content = resp?.data?.data;
                        const totalApplicant =
                            (content?.exclude?.length > 0
                                ? content?.exclude
                                    .map((item) => parseInt(item?.count_adm_no_exclude))
                                    .reduce((prev, next) => prev + next)
                                : 0) +
                            (content?.unsuccessful?.length > 0
                                ? content?.unsuccessful
                                    .map((item) => parseInt(item?.count_adm_no_exclude))
                                    .reduce((prev, next) => prev + next)
                                : 0);
                        setTotalApplicant(totalApplicant);
                        setApplicantSummary(resp?.data?.data);
                    }
                }
            }
        });

        // get list Disbursed;
        GetApplications(academyYear?.value, exercise?.value, "", "A").then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {
                            let awardContent = [];
                            resp?.data?.data?.map(async (item, index) => {
                                if (awardContent.length > 0) {
                                    if (
                                        awardContent.filter((rewardItem) => {
                                            return (
                                                item?.rec_reward_type == rewardItem?.rec_reward_type
                                            );
                                        }).length > 0
                                    ) {
                                        const getIndex = awardContent.findIndex(
                                            (rewardItem, index) => {
                                                return (
                                                    item?.item?.rec_reward_type ==
                                                    rewardItem?.rec_reward_type &&
                                                    item?.item?.rec_reward_code ==
                                                    rewardItem?.rec_reward_code
                                                );
                                            }
                                        );

                                        awardContent[getIndex].count += 1;
                                    } else {
                                        awardContent.push({
                                            reward_type: item?.rec_reward_type,
                                            reward_code: item?.rec_reward_code,
                                            count: 1,
                                            quantum: 0,
                                            numbers: [],
                                        });
                                    }
                                } else {
                                    awardContent.push({
                                        reward_type: item?.rec_reward_type,
                                        reward_code: item?.rec_reward_code,
                                        count: 1,
                                        quantum: 0,
                                        numbers: [],
                                    });
                                }
                            });

                            awardContent?.map(async (item, index) => {
                                const quantumValue =
                                    (await getQuantumValue(
                                        item?.rec_reward_type,
                                        item?.rec_reward_code
                                    )) == undefined
                                        ? 0
                                        : await getQuantumValue(
                                            item?.rec_reward_type,
                                            item?.rec_reward_code
                                        );
                                awardContent[index].quantum = quantumValue;
                            });

                            setDisbursed(awardContent);
                        }
                    }
                }
            }
        );

        // get list Excluded
        GetApplications(academyYear?.value, exercise?.value, "", "", "E").then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {
                            let awardContent = [];
                            resp?.data?.data?.map(async (item, index) => {
                                if (awardContent.length > 0) {
                                    if (
                                        awardContent.filter((rewardItem) => {
                                            return (
                                                item?.rec_reward_type == rewardItem?.rec_reward_type
                                            );
                                        }).length > 0
                                    ) {
                                        const getIndex = awardContent.findIndex(
                                            (rewardItem, index) => {
                                                return (
                                                    item?.item?.rec_reward_type ==
                                                    rewardItem?.rec_reward_type &&
                                                    item?.item?.rec_reward_code ==
                                                    rewardItem?.rec_reward_code
                                                );
                                            }
                                        );

                                        awardContent[getIndex].count += 1;
                                    } else {
                                        awardContent.push({
                                            reward_type: item?.rec_reward_type,
                                            reward_code: item?.rec_reward_code,
                                            count: 1,
                                            quantum: 0,
                                            numbers: [],
                                        });
                                    }
                                } else {
                                    awardContent.push({
                                        reward_type: item?.rec_reward_type,
                                        reward_code: item?.rec_reward_code,
                                        count: 1,
                                        quantum: 0,
                                        numbers: [],
                                    });
                                }
                            });

                            awardContent?.map(async (item, index) => {
                                const quantumValue =
                                    (await getQuantumValue(
                                        item?.rec_reward_type,
                                        item?.rec_reward_code
                                    )) == undefined
                                        ? 0
                                        : await getQuantumValue(
                                            item?.rec_reward_type,
                                            item?.rec_reward_code
                                        );
                                awardContent[index].quantum = quantumValue;
                            });

                            setExcluded(awardContent);
                        }
                    }
                }
            }
        );

        // get list Unsuccessful
        GetApplications(academyYear?.value, exercise?.value, "", "N").then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {
                            let awardContent = [];
                            resp?.data?.data?.map(async (item, index) => {
                                if (awardContent.length > 0) {
                                    if (
                                        awardContent.filter((rewardItem) => {
                                            return (
                                                item?.rec_reward_type == rewardItem?.rec_reward_type
                                            );
                                        }).length > 0
                                    ) {
                                        const getIndex = awardContent.findIndex(
                                            (rewardItem, index) => {
                                                return (
                                                    item?.item?.rec_reward_type ==
                                                    rewardItem?.rec_reward_type &&
                                                    item?.item?.rec_reward_code ==
                                                    rewardItem?.rec_reward_code
                                                );
                                            }
                                        );

                                        awardContent[getIndex].count += 1;
                                    } else {
                                        awardContent.push({
                                            reward_type: item?.rec_reward_type,
                                            reward_code: item?.rec_reward_code,
                                            count: 1,
                                            quantum: 0,
                                            numbers: [],
                                        });
                                    }
                                } else {
                                    awardContent.push({
                                        reward_type: item?.rec_reward_type,
                                        reward_code: item?.rec_reward_code,
                                        count: 1,
                                        quantum: 0,
                                        numbers: [],
                                    });
                                }
                            });

                            awardContent?.map(async (item, index) => {
                                const quantumValue =
                                    (await getQuantumValue(
                                        item?.rec_reward_type,
                                        item?.rec_reward_code
                                    )) == undefined
                                        ? 0
                                        : await getQuantumValue(
                                            item?.rec_reward_type,
                                            item?.rec_reward_code
                                        );
                                awardContent[index].quantum = quantumValue;
                            });

                            setUnsuccessful(awardContent);
                        }
                    }
                }
            }
        );

        //get total Applicant
        GetApplications(academyYear?.value, exercise?.value).then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {
                            // setTotalApplicant(resp?.data?.totalData)
                        }
                    }
                }
            })
    };
    const handleGetListAcademyYear = async () => {
        setLoadingAcademyYear(true);

        try {
            setAcademyYear(null);

            const response = await getAcademyYear();
            const academyYears = response?.data?.data?.map((item) => ({
                label: item?.acad_yr,
                value: item?.acad_yr,
            }))
            const sortedAcademyYears = sortDescendingByKey(academyYears, "value");

            setDataAcademyYear(sortedAcademyYears);

        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingAcademyYear(false);
        }
    };
    const handleGetListAwardType = async () => {
        getCodeTypeSasList("AWARDTYPECODE").then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setAwardTypeList(resp?.data?.data);
                    }
                }
            }
        });
    };
    const handleGetListAwardCode = (awardTypeCode) => {
        getCodeTypeSasList(awardTypeCode).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setAwardCodeList(resp?.data?.data);
                    }
                }
            }
        });
    };
    const exportToXlsx = useCallback(() => {
        const wb = utils.book_new();

        const tableRefs = [
            { ref: table1Ref, name: "Application Summary Report" },
            { ref: table2Ref, name: "Total no. of applicants" },
            { ref: table3Ref, name: "No. of Awards to be Disbursed" },
            { ref: table4Ref, name: "Excluded Applicants" },
            { ref: table5Ref, name: "Unsuccessfull Applicants" },
        ]

        tableRefs.forEach(({ ref, name }) => {
            const elt = ref.current;
            if (elt) {
                const ws = utils.table_to_sheet(elt);
                utils.book_append_sheet(wb, ws, name);
            }
        });

        writeFileXLSX(wb, "applicants-summary.xlsx");
    }, [reportDataRef]);

    const handleExport = () => {
        let optionChecked = "";

        let pdfChecked = refPDF.current.checked;
        let csvChecked = refCSV.current.checked;
        if (pdfChecked) {
            optionChecked = "PDF";
        } else if (csvChecked) {
            optionChecked = "CSV";
        }

        if (optionChecked == "PDF") {
            const doc = new jsPDF({
                format: "a1",
                unit: "px",
                orientation: "landscape",
            });

            doc.setFontSize(10);

            doc.html(reportDataRef.current, {
                callback: (doc) => {
                    doc.save("app-summary.pdf");
                },
                x: 120,
                y: 50,
            });
        } else if (optionChecked == "CSV") {
            exportToXlsx();
        }
    };

    return (
        <Layout currentNav={"reports"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item active>Application Summary</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Print Application Summary</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                {" "}
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>

                    <Form
                        className={` d-flex flex-column gap-3 collapse-animation`}
                        // onSubmit={handleSubmit()}
                    >
                        <Row>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Academic Year</Form.Label>
                                    <Select
                                        onChange={(value) => setAcademyYear(value)}
                                        options={dataAcademyYear}
                                        placeholder={`Academic Year`}
                                        value={academyYear}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Exercise</Form.Label>
                                    <Select
                                        isDisabled={!academyYear?.value}
                                        onChange={(value) => setExercise(value)}
                                        options={dataExercise}
                                        value={exercise}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Button
                                    type="button"
                                    onClick={() => handleRetrieve()}
                                    className="btn btn-primary"
                                >
                                    Retrieve
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {showRetrieve && (
                    <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                        <Row>
                            <Col xs={12}>
                                <div className="text-end">
                                    <Button
                                        type="button"
                                        className="btn btn-primary ms-auto mb-5"
                                        onClick={() => setShowModalExport(true)}
                                    >
                                        Export
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <div ref={reportDataRef}>
                            <Table id="table-0" ref={table1Ref}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.DataCell>
                                            <h3 className="text-center py-3 mb-0">
                                                Application Summary Report
                                            </h3>
                                        </Table.DataCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.DataCell className="mb-0">
                                            Financial Assistance Application Exercise
                                        </Table.DataCell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>

                            <Table id="table-1" ref={table2Ref}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.DataCell
                                            style={{width: "200px"}}
                                            className="border-bottom-0 py-0"
                                        >
                                            Total no. of applicants
                                        </Table.DataCell>
                                        <Table.DataCell
                                            style={{width: "70px"}}
                                            className="border-bottom-0 py-0"
                                        >
                                            {totalApplicant}
                                        </Table.DataCell>
                                        <Table.DataCell
                                            style={{width: "210px"}}
                                            className="border-bottom-0 py-0"
                                        >
                                            {/* - China */}
                                        </Table.DataCell>
                                        <Table.DataCell className="border-bottom-0 py-0">
                                            {/* 3 */}
                                        </Table.DataCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.DataCell
                                            style={{width: "200px"}}
                                            className="border-bottom-0 py-0"
                                        ></Table.DataCell>
                                        <Table.DataCell
                                            style={{width: "70px"}}
                                            className="border-bottom-0 py-0"
                                        ></Table.DataCell>
                                        <Table.DataCell
                                            style={{width: "210px"}}
                                            className="border-bottom-0 py-0"
                                        >
                                            {/* - Singapore Citizen */}
                                        </Table.DataCell>
                                        <Table.DataCell className="border-bottom-0 py-0">
                                            {/* 21 */}
                                        </Table.DataCell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>

                            <Table id="table-2" ref={table3Ref}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="py-0 border-bottom-0 text-sm text-primary">
                                            No. of Awards to be Disbursed
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row className="bg-light">
                                        <Table.HeaderCell className="py-0">Count</Table.HeaderCell>
                                        <Table.HeaderCell className="py-0">Award</Table.HeaderCell>
                                        <Table.HeaderCell className="py-0">
                                            Quantum
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="py-0">
                                            Number/Breakdown
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {disbursed?.map((content, index) => {
                                        return (
                                            <Table.Row>
                                                <Table.DataCell>{content?.count}</Table.DataCell>
                                                <Table.DataCell>
                                                    {content?.reward_type} {" - "} {content?.reward?.code}
                                                </Table.DataCell>
                                                <Table.DataCell>{content?.quantum}</Table.DataCell>
                                                <Table.DataCell>-</Table.DataCell>
                                            </Table.Row>
                                        );
                                    })}

                                    {/* Total */}
                                    <Table.Row>
                                        <Table.DataCell
                                            colSpan={3}
                                            className="py-0 fw-bold text-danger text-sm border-bottom-0"
                                        >
                                            TOTAL NUMBER OF AWARDS TO BE DISBURSED:{" "}
                                            {disbursed?.reduce(
                                                (acc, current) => acc + current?.count,
                                                0
                                            )}
                                        </Table.DataCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.DataCell
                                            colSpan={3}
                                            className="py-0 fw-bold text-danger text-sm border-bottom-0"
                                        >
                                            TOTAL AMOUNT TO BE DISBURSED: $
                                            {disbursed?.reduce(
                                                (acc, current) => acc + current?.quantum,
                                                0
                                            )}
                                        </Table.DataCell>
                                    </Table.Row>
                                </Table.Body>
                            </Table >

                            <Table className="mt-5" id="table-3" ref={table4Ref}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="py-0 border-bottom-0 text-sm text-primary">
                                            Excluded Applicants
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row className="bg-light">
                                        <Table.HeaderCell className="py-0">
                                            No. Rejected
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="py-0">
                                            Citizen
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="py-0">
                                            Details
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {applicantSummary?.exclude?.map((content, index) => {
                                        return (
                                            <Table.Row>
                                                <Table.DataCell>
                                                    {content?.count_adm_no_exclude}
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    {content?.exclude_citizen}
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    {content?.exclude_remark}
                                                </Table.DataCell>
                                            </Table.Row>
                                        );
                                    })}

                                    {/* Total */}
                                    <Table.Row>
                                        <Table.DataCell className="py-0 fw-bold text-danger border-bottom-0">
                                            TOTAL NO. OF EXCLUDED APPLICANTS:{" "}
                                            {applicantSummary?.exclude?.reduce(
                                                (acc, current) => acc + parseInt(current.count_adm_no_exclude),
                                                0
                                            )}
                                        </Table.DataCell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>

                            <Table className="mt-5" id="table-4" ref={table5Ref}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="py-0 border-bottom-0 text-sm text-primary">
                                            Unsuccessfull Applicants
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row className="bg-light">
                                        <Table.HeaderCell className="py-0">
                                            No. Rejected
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="py-0">
                                            Citizen
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="py-0">
                                            Details
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {applicantSummary?.unsuccessful?.map((content, index) => {
                                        return (
                                            <Table.Row>
                                                <Table.DataCell>
                                                    {content?.count_adm_no_exclude}
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    {content?.exclude_citizen}
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    {content?.exclude_remark}
                                                </Table.DataCell>
                                            </Table.Row>
                                        );
                                    })}

                                    {/* Total */}
                                    <Table.Row>
                                        <Table.DataCell
                                            colSpan={3}
                                            className="py-0 fw-bold text-danger text-xl border-bottom-0"
                                        >
                                            TOTAL NO. OF UNSUCCESSFUL APPLICANTS:{" "}
                                            {applicantSummary?.unsuccessful?.reduce(
                                                (acc, current) => acc + current.count_adm_no_exclude,
                                                0
                                            )}
                                        </Table.DataCell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                )}
                <CustomModal
                    show={showModalExport}
                    title={`Export`}
                    handleClose={() => setShowModalExport(false)}
                    modalSize={"md"}
                    withIcon={false}
                    description={
                        <div>
                            <div>
                                <b>Export as</b>
                            </div>
                            <div>
                                <FormCheck
                                    type="radio"
                                    name="export"
                                    label="PDF"
                                    value="pdf"
                                    id="pdf"
                                    ref={refPDF}
                                />
                                <FormCheck
                                    ref={refCSV}
                                    type="radio"
                                    name="export"
                                    label="CSV"
                                    value="csv"
                                    id="csv"
                                />
                            </div>
                        </div>
                    }
                    submitButtonText={`Export`}
                    action={handleExport}
                />
            </sgds-content-body>
        </Layout>
    );
}

export default PrintApplicationSummary;
