import { apiCall } from "@/utils/apiCall";

export function getAcademyYear() {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines/distincts/acadyears`,
    {},
    "GET"
  );
}

export function getExercise(parameters) {
  let url =
    process.env.REACT_APP_API_URL +
    "/applications/timelines/distincts/exercises?acad_yr=" +
    parameters?.acad_yr;

  if (parameters?.acad_yr == null || parameters?.acad_yr == "" || parameters?.acad_yr == undefined) {
    url =
      process.env.REACT_APP_API_URL +
      "/applications/timelines/distincts/exercises";
  }

  return apiCall(url, {}, "GET");
}
