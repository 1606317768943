import React, {useEffect, useState} from "react";

import {Col, Form, Row} from "@govtechsg/sgds-react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";

import {getOccupationList} from "@/apis/preparation/setup-occupation/api";

const statusOptions = [
    {
        label: "Living in the same household",
        value: "L",
    },
    {
        label: "Not living in the same household",
        value: "NL",
    },
    {
        label: "Deceased",
        value: "DE",
    },
    {
        label: "Divorced. Living with applicant",
        value: "DL",
    },
    {
        label: "Divorced. Not living with applicant",
        value: "DN",
    },
    {
        label: "In-Jail",
        value: "IJ",
    },
];

function Father({
                    data,
                    listParent,
                    setListParent,
                    studentDetail,
                    isDisabled,
                    handleParentChange,
                    getTotalSumIncome,
                    fatherIndex,
                    isReadOnly = false,
                    enabler
                }) {
    const [statusOccupationList, setStatusOccupationList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(fatherIndex);

    const [selectedStatus, setSelectedStatus] = useState({
        label: "",
        value: "",
    });
    const [selectedOccupation, setSelectedOccupation] = useState({
        label: "",
        value: "",
    });

    useEffect(() => {
        if (listParent?.length > 0) {
            listParent?.map((item, index) => {
                if (item?.relationship == "F") {
                    statusOptions.map((content) => {
                        if (item?.status == content.value) {
                            setSelectedStatus({
                                label: content.label,
                                value: content.value,
                            });
                        }
                    });

                    statusOccupationList.map((content) => {
                        if (
                            listParent[currentIndex]?.faas_occupations?.hasOwnProperty("id")
                        ) {
                            if (
                                listParent[currentIndex]?.faas_occupations?.id ===
                                content?.value
                            ) {
                                setSelectedOccupation({
                                    label: content.label,
                                    value: content.value,
                                    isIncomeContribute: content.is_income_contribute,
                                    isRemarkRequired: content.is_remark_required,
                                });
                            }
                        } else {
                            if (
                                listParent[currentIndex]?.faas_occupations == content?.value
                            ) {
                                setSelectedOccupation({
                                    label: content.label,
                                    value: content.value,
                                    isIncomeContribute: content.is_income_contribute,
                                    isRemarkRequired: content.is_remark_required,
                                });
                            }
                        }
                    });
                }
            });
        }
    }, [listParent]);

    useEffect(() => {
        getOccupationList(0, 1, "N").then((resp) => {
            if (resp?.status == 200) {
                let contentArray = [];
                resp?.data?.data?.map((content) => {

                    contentArray.push({
                        label: content.occupation,
                        value: content.id,
                        is_income_contribute: content.income_contribution,
                        is_remark_required: content.remark,
                    });

                });
                setStatusOccupationList(contentArray);
            }
        });
    }, []);

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={enabler ? isReadOnly ? true : listParent?.length === 0 : true}
                                value={listParent[currentIndex]?.name ?? ""}
                                onChange={(e) => {
                                    handleParentChange("name", e.target.value, currentIndex);
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Status</Form.Label>
                            <Select
                                options={statusOptions}
                                isDisabled={enabler ? isReadOnly ? true : listParent?.length === 0 : true}
                                value={selectedStatus}
                                onChange={(selectedOption) => {
                                    setSelectedStatus({
                                        label: selectedOption.label,
                                        value: selectedOption.value,
                                    });
                                    handleParentChange(
                                        "status",
                                        selectedOption.value,
                                        currentIndex
                                    );
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>NRIC / Passport No</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={enabler ? isReadOnly ? true : listParent?.length === 0 : true}
                                required={selectedStatus?.value == "DL" ? true : false}
                                value={listParent[currentIndex]?.id_no ?? ""}
                                onChange={(e) =>
                                    handleParentChange("id_no", e.target.value, currentIndex)
                                }
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group className="d-flex flex-column">
                            <Form.Label>Birth date</Form.Label>
                            <DatePicker
                                className={`form-control`}
                                selected={listParent[currentIndex]?.birthdate == undefined || listParent[currentIndex]?.birthdate == null ? null : listParent[currentIndex]?.birthdate}
                                maxDate={new Date()}
                                onChange={(date) => {
                                    if (date == null) {
                                        handleParentChange("birthdate", "", currentIndex);
                                        handleParentChange("age", 0, currentIndex);
                                    } else {
                                        const choosenDate = moment(date).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        );
                                        handleParentChange("birthdate", choosenDate, currentIndex);
                                        handleParentChange(
                                            "age",
                                            moment().diff(choosenDate, "years"),
                                            currentIndex
                                        );
                                    }
                                }}
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                disabled={
                                    enabler ?
                                        isReadOnly ? true :
                                            listParent?.length === 0 ||
                                            selectedStatus?.value == "DE" : true
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Age</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={listParent[currentIndex]?.age ?? ""}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Occupation</Form.Label>
                            <Select
                                options={statusOccupationList}
                                isDisabled={
                                    enabler ?
                                        isReadOnly ? true :
                                            listParent?.length === 0 ||
                                            selectedStatus?.value == "DE" ||
                                            selectedStatus?.value == "IJ" : true
                                }
                                value={selectedOccupation}
                                onChange={(selectedOption) => {
                                    handleParentChange(
                                        "faas_occupations",
                                        selectedOption.value,
                                        currentIndex
                                    );

                                    setSelectedOccupation({
                                        label: selectedOption.label,
                                        value: selectedOption.value,
                                        isIncomeContribute: selectedOption.is_income_contribute,
                                        isRemarkRequired: selectedOption.is_remark_required,
                                    });

                                    if (selectedOption.is_income_contribute != "Y") {
                                        handleParentChange("mthly_income_contrib", 0, currentIndex);
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Other Income</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={enabler ? isReadOnly ? true : listParent?.length === 0 : true}
                                value={listParent[currentIndex]?.other_income ?? ""}
                                onChange={(e) => {
                                    handleParentChange(
                                        "other_income",
                                        e.target.value,
                                        currentIndex
                                    );

                                    0
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Gross Monthly Income</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={
                                    enabler ?
                                        isReadOnly ? true :
                                            listParent?.length === 0 ||
                                            selectedOccupation.isIncomeContribute != "Y" ||
                                            selectedStatus?.value == "DE" ||
                                            selectedStatus?.value == "DN" ||
                                            selectedStatus?.value == "IJ" : true
                                }
                                value={listParent[currentIndex]?.mthly_income_contrib ?? ""}
                                onChange={(e) => {
                                    handleParentChange(
                                        "mthly_income_contrib",
                                        e.target.value,
                                        currentIndex
                                    );

                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>

                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={
                                    enabler ?
                                        isReadOnly ? true :
                                            listParent?.length === 0 ||
                                            selectedOccupation.isRemarkRequired != "Y" ||
                                            selectedStatus?.value !== "NL" : true
                                }
                                required={selectedStatus?.value == "NL" ? true : false}
                                value={listParent[currentIndex]?.remark ?? ""}
                                onChange={(e) => {
                                    handleParentChange("remark", e.target.value, currentIndex);
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Father;
