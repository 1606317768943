import { apiCall } from "@/utils/apiCall";
import { createUrlWithParams } from "@/components/utils/utils";

// {
//     "AWARD_TYPE": "MMM",
//     "AWARD": "singaporeI",
//     "QUANTUM": 20000
// }

export async function getAwardList(limit = 20, page = 1) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/awards/details?limit=${limit}&page=${page}`,
    {},
    "GET"
  );
}

export async function getAwardListWithParams(awardType, award) {
  let params = {};
  if (awardType !== null && awardType !== "" && awardType !== undefined) {
    params = {
      ...params,
      award_type: awardType,
    };
  }
  if (award !== null && award !== "" && award !== undefined) {
    params = {
      ...params,
      award: award,
    };
  }

  let baseUrl = `${process.env.REACT_APP_API_URL}/applications/awards/details`;
  const url = createUrlWithParams(baseUrl, params);
  return apiCall(url, {}, "GET");
}

export function postAward(awardData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/awards/details`,
    awardData,
    "POST"
  );
}

export function putAward(awardID, awardData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/awards/details/${awardID}`,
    awardData,
    "PUT"
  );
}

export function deleteAward(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/awards/details/${selectedId}`,
    {},
    "DELETE"
  );
}
