import { apexAxiosCall, apiCall } from "@/utils/apiCall";

export async function getStaffList(search = "") {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/getStaffList",
    {
      dummy_account: "Y",
      empm_type: "F",
      system: "FAAS",
      page: "1",
      limit: "20",
      search: search,
      sort: "staff_id",
    }
  );
}

export async function getRoleStaffList(search = "", page = 1, limit = 20) {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/getStaffRoleList",
    {
      dummy_account: "Y",
      empm_type: "F",
      system: "FAAS",
      page: page,
      limit: limit,
      search: search,
      sort: "staff_id",
    }
  );
}

export function getStaffDetail(userId, system = "FAAS", role_tag = "Y") {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/getStaffInfo",
    {
      userId: userId,
      system: system,
      role_tag: role_tag,
    }
  );
}

export function getRolesList() {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/getSysRoles",
    {
      application_sys: "FAAS",
    }
  );
}

export const updateRoleSingleEmployee = (
  staff_id = "",
  application_sys = "",
  assigned_by = "",
  last_upd_id = "",
  role = "",
  org_role = ""
) => {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/saveStaffRoles",
    {
      data: [
        {
          staff_id,
          application_sys,
          assigned_by,
          last_upd_id,
          role,
          org_role,
          action: org_role == "" ? "I" : "U",
        },
      ],
    }
  );
};

export const updateRoleMultipleEmployee = (data) => {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/saveStaffRoles",
    {
      data,
    }
  );
};

export const deleteRole = (
  staff_id = "",
  application_sys = "",
  assigned_by = "",
  last_upd_id = "",
  role = "",
  org_role = ""
) => {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/saveStaffRoles",
    {
      data: [
        {
          staff_id,
          application_sys,
          assigned_by,
          last_upd_id,
          role,
          org_role,
          action: "D",
        },
      ],
    }
  );
};

export function getApplications() {
  return apiCall(`${process.env.REACT_APP_API_URL}/applications`, {}, "GET");
}
