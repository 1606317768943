import { Link } from "react-router-dom";

export default function Toc({ currentNav }) {
  let listContent = [];

  if (currentNav === "processing") {
    listContent = [
      "Award Information",
      "Eligibility Criteria",
      "Course",
      "Citizenship",
      "CWA",
      "GPA",
      "Exclude Received Award Type",
    ];
  }

  if (listContent.length <= 0) return null;

  return (
    <div className="mt-5">
      <h4 className="fw-bold py-2">On This Page</h4>
      <div
        className="d-flex flex-column"
        style={{ borderLeft: "1px solid #9FA09F" }}
      >
        {listContent.map((item) => {
          return (
            <Link
              key={item}
              to={`/processing/criteria?scrollTo=${item}`}
              className="px-3 py-2"
            >
              {item}
            </Link>
          );
        })}
      </div>
    </div>
  );
}
