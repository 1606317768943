import React from "react";
import * as jose from "jose";
import { v4 as uuidv4 } from "uuid";
import { sha256 } from "js-sha256";
// import jwt from "jsonwebtoken";
import { jwtDecode } from "jwt-decode";
var CryptoJS = require("crypto-js");

// FUNCTION TO RETURN STRINGIFY OUTPUT
function stringify(json) {
  return JSON.stringify(json, null);
}

// FUNCTION TO RETURN SHA-256 ENCODING
function sha256Hash(string) {
  const signature = CryptoJS.SHA256(string);
  return signature.toString(CryptoJS.enc.hex);
}

// FUNCTION TO RETURN PRIVATE KEY IN PKCS8 FORMAT
const importKey = async (key) => {
  const importedKey = await jose.importJWK(key, "ES256");

  if (typeof importedKey === "object" && "type" in importedKey) {
    return importedKey;
  } else {
    throw new Error("Imported key is not of type KeyLike");
  }
};

// FUNCTION TO GENERATE JWT
const getJWT = async (iss, sub, kid, aud, hash, privateKey) => {
  // GET THE PKCS8 Signing Key
  // This can be from a file or generated by a JWK in Endian format.
  // const caPrivateKey = fs.readFileSync('./private.key', 'utf8');
  // const caPrivateKey = await importKey(privateKey);
  const caPrivateKey = await importKey(privateKey);
  const signOptions = {
    algorithm: "ES256",
    keyid: kid,
    expiresIn: "180s",
    jwtid: uuidv4(),
    issuer: iss,
    audience: aud,
    subject: sub,
  };

  const payload = {
    data: hash,
  };

  // create jwt
  // The JWT is created here to be used for the authentication header.
  // const jwtAuth = jwt.sign(payload, caPrivateKey, signOptions);
  // const jwtDecoded = jwt.decode(jwtAuth, { complete: true });

  const payloadThings = {
    iss: iss,
    sub: sub,
    aud: aud,
    exp: "180s", // 1 hour
    jti: uuidv4(),
    data: hash,
  };

  const jwtAuth = await new jose.SignJWT(payloadThings)
    .setProtectedHeader({ alg: "ES256", kid, typ: "JWT" })
    .setIssuedAt()
    .setIssuer(iss)
    .setAudience(aud)
    .setSubject(sub)
    .setExpirationTime("180s")
    .sign(caPrivateKey);

  const joseDecoded = await new jose.decodeJwt(jwtAuth);

  return jwtAuth;
};

/*
 ***** START OF PROGRAM *****
    ***** DEFINE SIGNING VARIABLES *****
    Here the following JWT claims are defined:
    - issuer : API Key(s) with the format: (1) api-key-1 or (2) api-key-1,api-key2
    - subject
    - key ID
    - audience
    - JSON payload
    - private key
    - payload hash (data)
    The code below contains a sample private key.
    In a real scenario this will be in a secure vault and should not be in program code.
*/

const getJwt = async (payload, method) => {
  try {
    const subject = method;

    // Secret Manager is no longer used due to C-Stack limitations; switched to local environment variables.
    const FAAS_SIMS_PRIVATE_KEY = String(
      process.env.REACT_APP_FAAS_SIMS_PRIVATE_KEY
    );

    const issuer = String(process.env.REACT_APP_FAAS_SIMS_API_KEY); // parse.simsApiKey;
    const audience = String(process.env.REACT_APP_FAAS_SIMS_AUDIENCE); // parse.simsAudience;
    const keyId = String(process.env.REACT_APP_FAAS_SIMS_KEY_ID); // parse.simsKeyId;
    const privateKey = JSON.parse(FAAS_SIMS_PRIVATE_KEY);

    const payloadString = JSON.stringify(payload);
    const hash = sha256Hash(payloadString);

    const result = await getJWT(
      issuer,
      subject,
      keyId,
      audience,
      hash,
      privateKey
    );
    return result;
  } catch (err) {
    throw new Error("Error generate apex jwt: " + err);
  }
};

export { getJwt };
