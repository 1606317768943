import React from "react";
import {
  getListAcadYears,
  getListExercise,
} from "@/apis/preparation/app-timeline/api";

import {
  getApplicationHomes,
  getMTCaseNotes,
  getMTCases,
  getMTCDetails,
  getMtConsent,
  getMTCRequest,
  getMTCRequestResult,
  getMtIncomes,
  postAppeal,
  postApplicationHomes,
} from "@/apis/app-records/homes/api";

import {
  getApplicationList,
  getScholarship,
  transferApplicationExercise,
  upsertScholarship,
} from "@/apis/app-records/maintenance/api";

import {
  getRemarkNYP,
  getRemarkPA,
} from "@/apis/preparation/setup-remarks/api";

import { getCodeList } from "@/apis/preparation/setup-dialect-etc/api";
import { getOccupationList } from "@/apis/preparation/setup-occupation/api";
import { getStudentDetail, getStudentListSiblingNYP } from "@/apis/sims/api";

import { toast } from "react-toastify";
import {sortExerciseList, sortDescendingByKey} from "@/components/utils/utils";

const getListOfAcadYears = (setListAcadYears) => {
  getListAcadYears("Y").then((resp) => {
    if (resp?.response?.data?.message) {
      if (
        resp?.response?.data?.message?.length > 0 &&
        Array.isArray(resp?.response?.data?.message)
      ) {
        resp?.response?.data?.message?.map((contentErr) => {
          toast.error(contentErr?.msg);
        });
      } else {
        toast.error(resp?.response?.data?.message);
      }
    } else if (resp?.response?.status == 404) {
      toast.error("Data not found");
    } else {
      if (resp.status == 200) {
        let listYears = [];
        resp?.data?.data?.map((item) => {
          listYears.push({ value: item?.acad_yr, label: item?.acad_yr });
        });

        const sortedAcademyYears = sortDescendingByKey(listYears, "value");

        setListAcadYears(sortedAcademyYears);
      }
    }
  });
};

const getListOfApplicationList = (setListAdmNo, params) => {
  getApplicationList(params).then((resp) => {
    if (resp?.response?.data?.message) {
      if (
        resp?.response?.data?.message?.length > 0 &&
        Array.isArray(resp?.response?.data?.message)
      ) {
        resp?.response?.data?.message?.map((contentErr) => {
          toast.error(contentErr?.msg);
        });
      } else {
        toast.error(resp?.response?.data?.message);
      }
    } else if (resp?.response?.status == 404) {
      toast.error("Data not found");
    } else {
      if (resp.status == 200) {
        let listContent = [];
        resp?.data?.data?.map((item) => {
          if (listContent.some((e) => e?.value === item?.adm_no)) {
          } else {
            listContent.push({ value: item?.adm_no, label: item?.adm_no });
          }
        });

        setListAdmNo(listContent);
      }
    }
  });
};

const getListOfRemarks = (setNYPRemarks, setPARemarks) => {
  getRemarkNYP(0,1).then((response) => {
    let contentA = [];
    response?.data?.data?.map((contentInside) => {
      contentA.push({
        label: contentInside?.remark,
        value: contentInside?.code,
      });
    });
    setNYPRemarks(contentA);
  });

  getRemarkPA().then((response) => {
    let contentA = [];
    response?.data?.data?.map((contentInside) => {
      contentA.push({
        label: contentInside?.sts_reason,
        value: contentInside?.sts_reason,
      });
    });
    setPARemarks(contentA);
  });
};

const handleGetSpecialMeansOptions = (setSpecialMeansStatusOptions) => {
  getCodeList("SPECIALMEANSSTATUS", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setSpecialMeansStatusOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetResidentialOptions = async (setResidentialOptions) => {
  getCodeList("RESIDENTIALSTATUS", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setResidentialOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetMaritalStatus = async (setMaritalStatusOptions) => {
  getCodeList("MS", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setMaritalStatusOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetRelationshipStatus = (setRelationshipOptions) => {
  getCodeList("RELATIONSHIP", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setRelationshipOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetConsentScopeOptions = (setConsentScopeOptions) => {
  getCodeList("CONSENTSCOPE", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setConsentScopeOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetLegalCapacityOptions = (setLegalCapacityOptions) => {
  getCodeList("LEGALCAPACITYFORMI", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setLegalCapacityOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetConsentTypeOptions = (setConsentTypeOptions) => {
  getCodeList("CONSENTTYPE" , 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setConsentTypeOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetConsentStatusOptions = (setConsentStatusOptions) => {
  setConsentStatusOptions([
    {
      label: "",
      value: "",
    },
    {
      label: "Y",
      value: "Y",
    },
    {
      label: "N",
      value: "N",
    }
  ])
  /*
  getCodeList("CONSENTSTATUS", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setConsentStatusOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
    */
};

const handleGetMtRequestOptions = (setMtRequestStatusOptions) => {
  getCodeList("MTREQUESTSTATUS", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setMtRequestStatusOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetSpecialCaseOptions = (setSpecialCaseOptions) => {
  getCodeList("SPECIALCASEINDICATOR", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setSpecialCaseOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetCareArrangementOptions = (setCareArrangementOptions) => {
  getCodeList("CAREARRANGEMENT", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setCareArrangementOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetStatusTagOptions = (setStatusTagOptions) => {
  getCodeList("STATUSTAG", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setStatusTagOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetCaseTypeOptions = (setCaseTypeOptions) => {
  getCodeList("CASETYPE", 0)
    .then((response) => {
      if (response.status == 200) {
        let listContent = [];
        response?.data?.data?.map((item) => {
          listContent.push({
            value: item?.code,
            label: `${item?.code} - ${item?.description}`,
          });
        });
        setCaseTypeOptions(listContent);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetOccupationOptions = (setOccupationOptions) => {
  getOccupationList(0,1,"N").then((resp) => {
    if (resp?.status == 200) {
      let contentArray = [];
      resp?.data?.data?.map((content) => {
        
          contentArray.push({
            label: content.occupation,
            value: content.occupation,
            is_income_contribute: content.income_contribution,
            is_remark_required: content.remark,
          });
        
      });
      setOccupationOptions(contentArray);
    }
  });
};

const handleGetSpecialCircumstanceOptions = (setSpecialCircumstanceOptions) => {
  getCodeList("SPECIALCIRCUMSTANCES", 0).then((resp) => {
    if (resp?.status == 200) {
      let contentArray = [];
      resp?.data?.data?.map((content) => {
          contentArray.push({
            value: content?.code,
            label: `${content?.code} - ${content?.description}`,
          });
        
      });
      setSpecialCircumstanceOptions(contentArray);
    }
  });
};

export function getInitializeOptions(
  setListAcadYears,
  setListAdmNo,
  setNYPRemarks,
  setPARemarks,
  setSpecialMeansStatusOptions,
  setResidentialOptions,
  setMaritalStatusOptions,
  setConsentScopeOptions,
  setLegalCapacityOptions,
  setConsentTypeOptions,
  setConsentStatusOptions,
  setMtRequestStatusOptions,
  setSpecialCaseOptions,
  setCareArrangementOptions,
  setRelationshipOptions,
  setStatusTagOptions,
  setCaseTypeOptions,
  setOccupationOptions,
  setSpecialCircumstanceOptions
) {

  Promise.all([
    getListOfAcadYears(setListAcadYears),
    getListOfApplicationList(setListAdmNo),
    getListOfRemarks(setNYPRemarks, setPARemarks),
    handleGetSpecialMeansOptions(setSpecialMeansStatusOptions),
    handleGetResidentialOptions(setResidentialOptions),
    handleGetMaritalStatus(setMaritalStatusOptions),
    handleGetRelationshipStatus(setRelationshipOptions),
    handleGetConsentScopeOptions(setConsentScopeOptions),
    handleGetLegalCapacityOptions(setLegalCapacityOptions),
    handleGetConsentTypeOptions(setConsentTypeOptions),
    handleGetConsentStatusOptions(setConsentStatusOptions),
    handleGetMtRequestOptions(setMtRequestStatusOptions),
    handleGetSpecialCaseOptions(setSpecialCaseOptions),
    handleGetCareArrangementOptions(setCareArrangementOptions),
    handleGetStatusTagOptions(setStatusTagOptions),
    handleGetCaseTypeOptions(setCaseTypeOptions),
    handleGetOccupationOptions(setOccupationOptions),
    handleGetSpecialCircumstanceOptions(setSpecialCircumstanceOptions)
  ])
}

export function processTransferToAnotherExercise(
  academicYear,
  selectedListAdmNo,
  selectedListExercise,
  transferToAnotherExerciseValue
) {
  transferApplicationExercise({
    acad_yr: academicYear,
    adm_no: selectedListAdmNo,
    exercise: selectedListExercise,
    new_exercise: transferToAnotherExerciseValue,
    staff_id: sessionStorage.getItem("staffId")
      ? sessionStorage.getItem("staffId")
      : process.env.REACT_APP_LAST_UPD_ID,
  }).then((resp) => {
    if (resp?.response?.data?.message) {
      if (
        resp?.response?.data?.message?.length > 0 &&
        Array.isArray(resp?.response?.data?.message)
      ) {
        resp?.response?.data?.message?.map((contentErr) => {
          toast.error(contentErr?.msg);
        });
      } else {
        toast.error(resp?.response?.data?.message);
      }
    } else if (resp?.response?.status == 404) {
      toast.error("Data not found");
    } else {
      if (resp.status == 200) {
        toast.success("Exercise transfered successfully");
      }
    }
  });
}

export function processGetStudentDetail(
  selectedListAdmNo,
  academicYear,
  setStudentDetail
) {
  getStudentDetail(
    selectedListAdmNo,
    // TEMP_ADM_NO,
    academicYear
  ).then((resp) => {
    if (resp?.response?.data?.message) {
      if (
        resp?.response?.data?.message?.length > 0 &&
        Array.isArray(resp?.response?.data?.message)
      ) {
        resp?.response?.data?.message?.map((contentErr) => {
          toast.error(contentErr?.msg);
        });
      } else {
        toast.error(resp?.response?.data?.message);
      }
    } else if (resp?.response?.status == 404) {
      toast.error("Data not found");
    } else {
      if (resp.status == 200) {
        if (resp?.data?.data) {
          setStudentDetail(resp?.data?.data[0]);
        }
      }
    }
  });
}

export function processGetListSiblingNYP(
  selectedListAdmNo,
  academicYear,
  setListSiblingInNYP
) {
  getStudentListSiblingNYP(selectedListAdmNo, academicYear).then((resp) => {
    if (resp?.response?.data?.message) {
      if (
        resp?.response?.data?.message?.length > 0 &&
        Array.isArray(resp?.response?.data?.message)
      ) {
        resp?.response?.data?.message?.map((contentErr) => {
          toast.error(contentErr?.msg);
        });
      } else {
        toast.error(resp?.response?.data?.message);
      }
    } else if (resp?.response?.status == 404) {
      toast.error("Data not found");
    } else {
      if (resp.status == 200) {
        if (resp?.data?.data) {
          setListSiblingInNYP(resp?.data?.data);
        }
      }
    }
  });
}

export function processGetMTCRequest(
  academicYear,
  selectedListExercise,
  selectedListAdmNo,
  setListMTCRequest
) {
  getMTCRequest(
    academicYear,
    selectedListExercise,
    selectedListAdmNo
    // TEMP_ADM_NO
  ).then((resp) => {
    if (resp?.response?.data?.message) {
      if (
        resp?.response?.data?.message?.length > 0 &&
        Array.isArray(resp?.response?.data?.message)
      ) {
        resp?.response?.data?.message?.map((contentErr) => {
          toast.error(contentErr?.msg);
        });
      } else {
        toast.error(resp?.response?.data?.message);
      }
    } else if (resp?.response?.status == 404) {
      toast.error("Data not found");
    } else {
      if (resp.status == 200) {
        if (resp?.data?.data) {
          setListMTCRequest(resp?.data?.data);
        }
      }
    }
  });
}
