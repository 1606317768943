import {Button,} from "@govtechsg/sgds-react";
import Layout from "@/components/Layout";
import React, {useEffect, useState} from "react";
import CustomModal from "@/components/CustomModal";
import {toast} from "react-toastify";
import RelationshipMenu from "@/components/Preparation/RelationshipStatus/Menu";
import {deleteCode, getCodeList, postCode, putCode, postOverwriteCode, postAppendCode} from "@/apis/preparation/setup-dialect-etc/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import SortableTable from "@/components/SortableTable";
import { downloadTemplate } from '@/components/utils/utils'
import UploadModal from "@/components/UploadModal";
import * as XLSX from "xlsx";
import moment from "moment-timezone";

export default function Dialect() {
    const [data, setData] = useState([
        {
            code_type: "DG",
            code: null,
            description: null,
        },
    ]);

    const [header, setHeader] = useState([
        {key: 'index', label: '#', required: false},
        {key: 'code', label: 'Code', required: true},
        {key: 'description', label: 'Dialect Group', required: false},
        {key: 'action', label: '', required: false},
    ]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);
    const itemsPerPage = 20;
    const [isShowErrorUpload , setIsShowErrorUpload] = useState(false);
    const [errorReportData, setErrorReportData] = useState([])

    const handleCancelEdit = (index) => {
        if (data[index].new_data === true) {
            const updatedData = data.filter(item => !item.new_data);
            setData(updatedData);
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = true;
            return newArr;
        });

        setIsEditing(false);
        getCodes()
    }

    const renderActionButtons = (row, rowIndex) => (
        <div className={`d-flex gap-1`}>
            {!editArr[rowIndex] ? (
                <div className={`d-flex gap-1`}>
                    <button
                        className={`btn-clear text-green-500`}
                        onClick={() => handleSubmitEdit(rowIndex, row.id)}
                    >
                        <i className={`bi bi-check-lg`}></i>
                        Save
                    </button>

                    <button
                        className={`btn-clear text-red-500`}
                        onClick={() => handleCancelEdit(rowIndex)}
                    >
                        <i className={`bi bi-x-circle`}></i>
                        {` `}
                        Cancel
                    </button>
                </div>
            ) : (
                <button
                    className={`btn-clear`}
                    disabled={isEditing}
                    onClick={() => handleEdit(rowIndex)}
                >
                    <i className={`bi bi-pencil`}></i>
                    {` `}
                    Edit
                </button>
            )}

            <button
                className={`btn-clear`}
                disabled={rowIndex == 0 ? row?.id ? isEditing : false : isEditing}
                onClick={() => {
                    setShow(true);
                    setSelectedId(row.id ?? null);
                    setSelectedIndex(rowIndex);
                }}
            >
            <i className={`bi bi-trash`}></i>
            </button>
        </div>
    );

    const renderCellContent = (row, rowIndex, key) => (
        editArr[rowIndex] ? (
            row[key]
        ) : (
            <input
                type={"text"}
                value={row[key]}
                onChange={(e) => handleChange(rowIndex, key, e.target.value)}
            />
        )
    );

    const [editArr, setEditArr] = useState([true]);

    const [show, setShow] = useState(false);

    const [selectedId, setSelectedId] = useState(null);

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [isEditing, setIsEditing] = useState(false);

    const [showUpload, setShowUpload] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [excelContent, setExcelContent] = useState([])

    const handleFileSelect = (file) => {
        setSelectedFile(file);
    };

    const postOverwrite = () => {
        // return false;
        if (!selectedFile) {
            toast.error(`Select file`);

            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);
            setExcelContent(jsonData)


            postOverwriteCode({data: jsonData})
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                            setIsShowErrorUpload(true)
                            setErrorReportData(response?.response?.data)
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        setIsShowErrorUpload(false)
                        setErrorReportData([])

                        toast.success(`Record updated successfully `);
                        getCodes()
                        setShowUpload(false);
                        setSelectedFile(null)
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const postAppend = () => {
        if (!selectedFile) {
            toast.error(`Select file`);

            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);
            setExcelContent(jsonData)

            postAppendCode({data: jsonData})
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);

                            setIsShowErrorUpload(true)
                            setErrorReportData(response?.response?.data)

                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        setIsShowErrorUpload(false)
                        setErrorReportData([])

                        toast.success(`Record updated successfully `);
                        setShowUpload(false);
                        getCodes()
                        setSelectedFile(null)
                    }

                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const downloadErrorReport = () => {
        const wb = XLSX.utils.book_new();

        let sheet1 = [];

        if (errorReportData?.message == "cannot overwrite or delete, codes is in use") {
            sheet1.push(["code_type", "code", "description", "error message"]);

            excelContent?.forEach((errorData) => {
                const match = errorReportData?.data?.find(
                    (reportData) =>
                        errorData.code_type === reportData.code_type &&
                        errorData.code === reportData.code
                );
            
                if (match) {
                    sheet1.push([errorData?.code_type, errorData?.code, errorData?.description, errorReportData?.message]);
                }
            });

        } else {
            sheet1.push(["value", "error_message"]);

            errorReportData?.data?.map((errorData) => {
                sheet1.push([errorData.value, errorData.msg])
            })
        }

        const ws = XLSX.utils.aoa_to_sheet(sheet1);
        XLSX.utils.book_append_sheet(wb, ws, "Template");

        XLSX.writeFile(wb, "error_import_codes_"+moment(Date.now()).tz("Asia/Singapore").format("YYYY_MM_DD_HH_mm_ss") +".xlsx");

    }




    const handleAddRow = () => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setData((prevArr) => {
            const newObj = {
                code_type: "DG",
                code: null,
                description: null,
            };
            return [newObj, ...prevArr];
        });

        setEditArr((prevEditArr) => {
            return [false, ...prevEditArr];
        });

        setIsEditing(true);
    };

    const handleEdit = (index) => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = false;
            return newArr;
        });

        setIsEditing(true);
    };

    const handleSubmitEdit = (index, CodeID) => {
        if (!data[index].code) {
            toast.error("Please fill out the required fields")
            return false
        }

        const CodeData = {
            code_type: "DG",
            code: data[index].code,
            description: data[index].description,
        };

        //submit data
        if (CodeID) {
            //update
            putCode(CodeID, CodeData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getCodes();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });
                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            //post
            postCode(CodeData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getCodes();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });

                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        }
    };

    const handleDeleteRow = () => {
        if (selectedId) {
            deleteCode(selectedId)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success("Record deleted successfully");
                        getCodes();
                        setShow(false);
                    }

                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            const updatedData = [...data];

            updatedData.splice(selectedIndex, 1);

            setData(updatedData);

            setShow(false);
            setIsEditing(false)

            const updatedEditArray = [...editArr];
            updatedEditArray.splice(selectedIndex, 1);
            setEditArr(updatedEditArray);
        }
    };

    const handleChange = (index, field, value) => {
        if(field === "code" && value.length > 20){
            return;
        }


        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            [field]: value,
        };
        setData(updatedData);
    };

    const getCodes = () => {
        getCodeList("DG", itemsPerPage, currentPage)
            .then((response) => {
                setData(response?.data.data);
                const newArr = Array.from({length: response?.data.data.length}).fill(
                    true
                );

                setEditArr(newArr);
                setTotalPages(response?.data?.totalPage);
                setIsNextPage(response?.data?.nextPage);
            })
            .catch((error) => {
                toast.error(error.response?.data.message);
            });
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleDownloadTemplate = () => {
        const wb = XLSX.utils.book_new();

        let sheet1 = [];

        sheet1.push(["code_type", "code", "description", "content1", "info1", "info2"]);

        const ws = XLSX.utils.aoa_to_sheet(sheet1);
        XLSX.utils.book_append_sheet(wb, ws, "Template");

        XLSX.writeFile(wb, "import_Codes_template.xlsx");
    }

    useEffect(() => {
        getCodes();
    }, [currentPage]);

    return (
        <Layout currentNav={"preparation"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Setup Preparation</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Setup Dialect Group/Marital Status & Other
                </Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Setup Dialect Group/Marital Status & Other</h2>
            </sgds-content-header-top>

            <div className="my-3">
                <RelationshipMenu activeMenu="dialect"/>
            </div>

            <sgds-content-area-bottom>
                <div className="d-flex gap-3">
                    <Button
                        variant={`outline-dark`}
                        onClick={handleAddRow}
                        className={`d-flex gap-3`}
                    >
                        <i className="bi bi-plus-circle"></i>
                        Add
                    </Button>
                    <Button
                        variant={`outline-dark`}
                        onClick={() => setShowUpload(true)}
                        className={`d-flex gap-3`}
                    >
                        <i className="bi bi-plus-circle"></i>
                        Upload new file
                    </Button>

                </div>

            </sgds-content-area-bottom>

            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm`}>
                    <SortableTable headers={header} data={data} renderActionButtons={renderActionButtons}
                                   renderCellContent={renderCellContent} setData={setData}/>

                    <div className="text-center">
                        <button
                            className={`btn-clear`}
                            onClick={prevPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
              {" "}
                            Page {currentPage} of {totalPages}{" "}
            </span>
                        <button
                            className={`btn-clear`}
                            onClick={nextPage}
                            disabled={!isNextPage}
                        >
                            Next
                        </button>
                    </div>
                    {/*<Table responsive>*/}
                    {/*    <TableHeader>*/}
                    {/*        <TableRow>*/}
                    {/*            <TableHeaderCell width={"5%"}>#</TableHeaderCell>*/}
                    {/*            <TableHeaderCell>Code*/}
                    {/*                <span className={`text-danger`}>{" "}*</span>*/}
                    {/*            </TableHeaderCell>*/}
                    {/*            <TableHeaderCell>Dialect Group*/}
                    {/*                <span className={`text-danger`}>{" "}*</span>*/}
                    {/*            </TableHeaderCell>*/}
                    {/*            <TableHeaderCell width={"10%"}></TableHeaderCell>*/}
                    {/*        </TableRow>*/}
                    {/*    </TableHeader>*/}

                    {/*    <TableBody>*/}
                    {/*        {data?.map((row, index) => (*/}
                    {/*            <TableRow key={index}>*/}
                    {/*                <TableDataCell>{index + 1}</TableDataCell>*/}
                    {/*                <TableDataCell>*/}
                    {/*                    {!editArr[index] ? (*/}
                    {/*                        <input*/}
                    {/*                            type={"text"}*/}
                    {/*                            value={row.code}*/}
                    {/*                            onChange={(e) =>*/}
                    {/*                                handleChange(index, "code", e.target.value)*/}
                    {/*                            }*/}
                    {/*                        />*/}
                    {/*                    ) : (*/}
                    {/*                        row.code*/}
                    {/*                    )}*/}
                    {/*                </TableDataCell>*/}
                    {/*                <TableDataCell>*/}
                    {/*                    {!editArr[index] ? (*/}
                    {/*                        <input*/}
                    {/*                            type={"text"}*/}
                    {/*                            value={row.description}*/}
                    {/*                            onChange={(e) =>*/}
                    {/*                                handleChange(index, "description", e.target.value)*/}
                    {/*                            }*/}
                    {/*                        />*/}
                    {/*                    ) : (*/}
                    {/*                        row.description*/}
                    {/*                    )}*/}
                    {/*                </TableDataCell>*/}
                    {/*                <TableDataCell>*/}
                    {/*                    <div className={`d-flex gap-1`}>*/}
                    {/*                        {!editArr[index] ? (*/}
                    {/*                            <button*/}
                    {/*                                className={`btn-clear text-green-500`}*/}
                    {/*                                onClick={() => handleSubmitEdit(index, row.id)}*/}
                    {/*                            >*/}
                    {/*                                <i className={`bi bi-check-lg`}></i>*/}
                    {/*                                {` `}*/}
                    {/*                                Save*/}
                    {/*                            </button>*/}
                    {/*                        ) : (*/}
                    {/*                            <button*/}
                    {/*                                className={`btn-clear`}*/}
                    {/*                                onClick={() => handleEdit(index)}*/}
                    {/*                            >*/}
                    {/*                                <i className={`bi bi-pencil`}></i>*/}
                    {/*                                {` `}*/}
                    {/*                                Edit*/}
                    {/*                            </button>*/}
                    {/*                        )}*/}

                    {/*                        <button*/}
                    {/*                            className={`btn-clear`}*/}
                    {/*                            onClick={() => {*/}
                    {/*                                setShow(true);*/}
                    {/*                                setSelectedId(row.id ?? null);*/}
                    {/*                                setSelectedIndex(index);*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            <i className={`bi bi-trash`}></i>*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                </TableDataCell>*/}
                    {/*            </TableRow>*/}
                    {/*        ))}*/}
                    {/*    </TableBody>*/}
                    {/*</Table>*/}
                </div>
            </sgds-content-body>
            <CustomModal
                show={show}
                handleClose={() => setShow(false)}
                action={handleDeleteRow}
                title={`Delete record?`}
                description={`The selected record will be removed from the list. Proceed to delete?`}
            />
            <UploadModal
                show={showUpload}
                handleClose={() => setShowUpload(false)}
                title={`Upload Codes`}
                onFileSelect={handleFileSelect}
                selectedFile={selectedFile}
                actionOverwrite={postOverwrite}
                actionAppend={postAppend}
                handleDownloadTemplate={handleDownloadTemplate}
                downloadErrorReport={downloadErrorReport}
                isShowError={isShowErrorUpload}

            />

        </Layout>
    );
}
