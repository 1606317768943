import React, { createContext, useContext, useState } from "react";

const SessionContext = createContext();

export const useSessionContext = () => {
  return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
  const [idToken, setIdToken] = useState("asdfasdf");
  const [roles, setRoles] = useState([]);

  return (
    <SessionContext.Provider value={{ idToken, setIdToken }}>
      {children}
    </SessionContext.Provider>
  );
};
