import {
  Button,
  Col,
  Form,
  Row,
  Collapse,
  Tabs,
  Tab,
  Badge,
  FormCheck,
  Table,
  Modal,
} from "@govtechsg/sgds-react";
import FormulaImg from "@/images/formula_img.png";

export default function FormulaModal({ show, handleClose, action }) {
  return (
    <Modal size={"xl"} show={show} onHide={handleClose} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Formula Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col>
              <Table responsive>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Acronym</Table.HeaderCell>
                    <Table.HeaderCell>Stands For</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.DataCell>GHI</Table.DataCell>
                    <Table.DataCell>
                      Gross Monthly Household Income
                    </Table.DataCell>
                    <Table.DataCell>
                      Total gross monthly household income of immediate and
                      non-immediate family members{" "}
                    </Table.DataCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.DataCell>-</Table.DataCell>
                    <Table.DataCell>Gross Income</Table.DataCell>
                    <Table.DataCell>
                      All income contributions from rental, self-employment,
                      business and salaried employment{" "}
                      <b>
                        (which includes basic salary, allowances, overtime pay,
                        bonus, etc before CPF deduction)
                      </b>
                      . Payments in kind, reimbursement for expenses and
                      National Service (NS) men are excluded.
                    </Table.DataCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.DataCell>PCI</Table.DataCell>
                    <Table.DataCell>Per Capita Income</Table.DataCell>
                    <Table.DataCell>
                      GHI divided by the number of imemdiate and non-immediate
                      family members
                      <img src={FormulaImg} alt="formula img" />
                    </Table.DataCell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Col>
            <Col xs={12}>
              <h5 className="text-blue">
                Explanation of immediate and non-immediate family members:{" "}
              </h5>
            </Col>
            <Col>
              <Table responsive>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>-</Table.HeaderCell>
                    <Table.HeaderCell>Student who unmarried</Table.HeaderCell>
                    <Table.HeaderCell>Student who is married</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.DataCell>Immediate family members </Table.DataCell>
                    <Table.DataCell>Parents</Table.DataCell>
                    <Table.DataCell>Spouse, children</Table.DataCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.DataCell>
                      Non Immediate family members{" "}
                    </Table.DataCell>
                    <Table.DataCell>
                      e.g. Grandparents, siblings, other relatives
                    </Table.DataCell>
                    <Table.DataCell>
                      e.g. Grandparents, siblings, other relatives
                    </Table.DataCell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer className={`gap-2`}></Modal.Footer>
    </Modal>
  );
}
