import React from "react";
import { Breadcrumb } from "@govtechsg/sgds-react/Breadcrumb";

function BreadCrumbHomes() {
  return (
    <Breadcrumb
      listProps={{
        className: "bg-light",
        foo: "bar",
      }}
    >
      <Breadcrumb.Item>Administer Application Record</Breadcrumb.Item>
      <Breadcrumb.Item active>Application HOMES</Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default BreadCrumbHomes;
