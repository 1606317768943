import { apiCall } from "@/utils/apiCall";
import { createUrlWithParams } from "@/components/utils/utils";

// {
//     "OCCUPATION": "STUDENT",
//     "INCOME_CONTRIBUTION": "Y",
//     "INDEPENDENT": "Y",
//     "REMARK": "Y"
// }

export async function getOccupationList(limit = 20, page = 1, isObsolete = "") {
  let params = {};
  if (isObsolete !== null && isObsolete !== "" && isObsolete !== undefined) {
    params = {
      ...params,
      obsolete_flag: isObsolete,
    };
  }
  params = {
    ...params,
    limit: limit,
    page: page,
  };

  let baseUrl = `${process.env.REACT_APP_API_URL}/applications/occupations`;
  const url = createUrlWithParams(baseUrl, params);
  return apiCall(url, {}, "GET");

}

export function postOccupation(OCData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/occupations`,
    OCData,
    "POST"
  );
}

export function putOccupation(OCID, OCData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/occupations/${OCID}`,
    OCData,
    "PUT"
  );
}

export function deleteOccupation(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/occupations/${selectedId}`,
    {},
    "DELETE"
  );
}
