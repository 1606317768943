import {
  Button,
  Table,
  TableBody,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@govtechsg/sgds-react";
import Layout from "@/components/Layout";
import { useEffect, useState } from "react";
import CustomModal from "@/components/CustomModal";
import { toast } from "react-toastify";
import UploadModal from "@/components/UploadModal";
import CspcMenu from "@/components/Preparation/SetupCspc/Menu";

export default function StatusReason() {
  const [data, setData] = useState([
    {
      statusReason: "1000",
    },
    {
      statusReason: "1000",
    },
    {
      statusReason: "1000",
    },
  ]);

  const [editArr, setEditArr] = useState([]);

  const [show, setShow] = useState(false);

  const [showUpload, setShowUpload] = useState(false);

  const [selectedId, setSelectedId] = useState(-1);

  const [statusReason, setStatusReason] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (file) => {
    console.log(file);
    setSelectedFile(file);
  };

  const handleAddRow = () => {
    setData((prevArr) => {
      const newArr = [...prevArr];

      const newObj = {
        statusReason: statusReason,
      };

      newArr.push(newObj);

      return newArr;
    });

    setEditArr((prevEditArr) => {
      const newEditArr = [...prevEditArr];

      newEditArr.push(true);

      return newEditArr;
    });
  };

  const handleEdit = (index) => {
    setEditArr((prevArr) => {
      const hasTrueValue = prevArr.some((value) => value === true);

      if (!hasTrueValue) {
        const newArr = [...prevArr];
        newArr[index] = true;
        return newArr;
      } else {
        toast.warning("Save changes before editing another row");
      }

      return prevArr;
    });
  };

  const handleSubmitEdit = (index) => {
    /*submit data*/
    setEditArr((prevArr) => {
      const newArr = [...prevArr];
      newArr[index] = false;
      return newArr;
    });

    toast.success("Record updated successfully");
  };

  const handleDeleteRow = () => {
    const indexToDelete = data.findIndex((item, index) => index === selectedId);

    if (indexToDelete !== -1) {
      const updatedData = data.filter((item, index) => index !== selectedId);
      const updatedEditArr = editArr.filter((_, i) => i !== indexToDelete);

      setData(updatedData);
      setEditArr(updatedEditArr);

      setShow(false);

      toast.success("Record deleted successfully");
    }
  };

  const handleStatusReasonChange = (index, value) => {
    setData((prevData) => {
      return prevData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            statusReason: value,
          };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    const newArr = Array.from({ length: data.length }).fill(false);
    setEditArr(newArr);
  }, []);

  return (
    <Layout currentNav={"preparation"}>
      <sgds-content-header-top>
        <h2>Status Reason</h2>
      </sgds-content-header-top>
      <sgds-content-area-bottom>
        <div className="d-flex gap-3">
          <Button
            variant={`outline-dark`}
            onClick={handleAddRow}
            className={`d-flex gap-3`}
          >
            <i className="bi bi-plus-circle"></i>
            Add 
          </Button>

          <Button
            variant={`outline-dark`}
            onClick={() => setShowUpload(true)}
            className={`d-flex gap-3`}
          >
            <i className="bi bi-plus-circle"></i>
            Upload new file
          </Button>
        </div>
      </sgds-content-area-bottom>

      <div className="my-3">
        <CspcMenu activeMenu="cspc_reason" />
      </div>

      <sgds-content-body>
        <div className={`p-3 bg-white shadow-sm`}>
          <Table responsive>
            <TableHeader>
              <TableRow>
                <TableHeaderCell width={"5%"}>#</TableHeaderCell>
                <TableHeaderCell>Status Reason</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableDataCell>{index + 1}</TableDataCell>
                  <TableDataCell>
                    {editArr[index] ? (
                      <input
                        type={"text"}
                        value={row.statusReason}
                        onChange={(e) =>
                          handleStatusReasonChange(index, e.target.value)
                        }
                      />
                    ) : (
                      row.statusReason
                    )}
                  </TableDataCell>
                  <TableDataCell>
                    <div className={`d-flex gap-1`}>
                      {editArr[index] ? (
                        <button
                          className={`btn-clear text-green-500`}
                          onClick={() => handleSubmitEdit(index)}
                        >
                          <i className={`bi bi-check-lg`}></i>
                        </button>
                      ) : (
                        <button
                          className={`btn-clear`}
                          onClick={() => handleEdit(index)}
                        >
                          <i className={`bi bi-pencil`}></i>
                        </button>
                      )}

                      <button
                        className={`btn-clear`}
                        onClick={() => {
                          setShow(true);
                          setSelectedId(index);
                        }}
                      >
                        <i className={`bi bi-trash`}></i>
                      </button>
                    </div>
                  </TableDataCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </sgds-content-body>
      <CustomModal
        show={show}
        handleClose={() => setShow(false)}
        action={handleDeleteRow}
        title={`Delete record?`}
        description={`The selected record will be removed from the list. Proceed to delete?`}
      />

      <UploadModal
        show={showUpload}
        handleClose={() => setShowUpload(false)}
        title={`Upload Constituency Secretariat Code`}
        onFileSelect={handleFileSelect}
      />
    </Layout>
  );
}
