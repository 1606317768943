import { apiCall } from "@/utils/apiCall";

export async function getListApplications(params) {
  const queryString = new URLSearchParams(params).toString();









  
  return apiCall(
      `${process.env.REACT_APP_API_URL}/applications?${queryString}`,
      null,
      "GET"
  );
}


export async function patchApplications(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications`,
    params,
    "PATCH"
  );
}

export async function postDownloadNewRecommendList(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/recommends/new`,
    params,
    "POST"
  );
}

export async function postDownloadNewRecommendListWithNewApplication(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/recommends/withnew`,
    params,
    "POST"
  );
}

export async function postDownloadNewRecommendListWithoutNewApplication(
  params
) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/recommends/withoutnew`,
    params,
    "POST"
  );
}

export async function getRecommendList(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/recommendations/basedoncriteria`,
    params,
    "POST"
  );
}
