import { apiCall } from "@/utils/apiCall";
import { createUrlWithParams } from "@/components/utils/utils";

export async function getListAcadYears(homes_tag = "") {
  let params = {};
  if (homes_tag !== null && homes_tag !== "") {
    params = {
      ...params,
      homes_tag: homes_tag,
    };
  }

  let baseUrl = `${process.env.REACT_APP_API_URL}/applications/timelines/distincts/acadyears`;
  const url = createUrlWithParams(baseUrl, params);
  return apiCall(url, {}, "GET");


}

export async function getListExercise(year, homes_tag ="") {
  let params = {};
  if (year !== null && year !== "") {
    params = {
      ...params,
      acad_yr: year,
    };
  }
  if (homes_tag !== null && homes_tag !== "") {
    params = {
      ...params,
      homes_tag: homes_tag,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL +"/applications/timelines/distincts/exercises";
  const url = createUrlWithParams(baseUrl, params);
  return apiCall(url, {}, "GET");

}
