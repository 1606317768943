import { Button, Col, Form, Row } from "@govtechsg/sgds-react";
import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Layout from "@/components/Layout";
import { useDropzone } from "react-dropzone";
import axios from "axios";

import { useMsal } from "@azure/msal-react";
import { Breadcrumb } from "@govtechsg/sgds-react/Breadcrumb";
// import { getStudentList } from "@/apis/preparation/setup-timeline/api";
import { getApplications } from "@/apis/utilities/api";
import Select from "react-select";
import {
  getStudentList,
  getStudentFileList,
  saveStudentFileUpload,
} from "@/apis/sims/api";

export default function UploadDocument() {
  const [isOpen, setIsOpen] = useState(true);
  const contentRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { instance, accounts } = useMsal();

  const [studentList, setStudentList] = useState([]);
  const [uploadList, setUploadList] = useState([]);
  const [applicationList, setApplicationList] = useState([]);

  const [selectedName, setSelectedName] = useState(null);
  const [selectedAdmNo, setSelectedAdmNo] = useState("");
  const [selectedAppId, setSelectedAppId] = useState("");
  const [selectedDocId, setSelectedDocId] = useState("");
  const refTimeoutSearch = useRef(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
  });

  const onSubmitUploadFile = async () => {
    /*
        const account = accounts[0];
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account,
        });
        const token = response.accessToken;
        */
    const accessToken = sessionStorage.getItem("accessToken");
    const idToken = sessionStorage.getItem("idToken");

    const file = selectedFile;

    saveStudentFileUpload({
      appln_id: selectedAppId,
      doc_id: selectedDocId,
      adm_no: selectedAdmNo,
      filename: file.name,
      last_upd_id: sessionStorage.getItem("staffId")
        ? sessionStorage.getItem("staffId")
        : process.env.REACT_APP_LAST_UPD_ID,
    });

    // const uploadUrl = `${siteUrl}/_api/web/getfolderbyserverrelativeurl('${folderPath}')/files/add(overwrite=true, url='${file.name}')`;

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": file.type,
    };

    // This is upload URL Deeeplabs
    const uploadUrl = `https://graph.microsoft.com/v1.0/sites/d569a3a3-7161-4d9e-a120-1e1cdb6977b9/drive/root:/${file.name}:/content`;

    // This is upload URL from JOEY
    // const uploadUrl = `https://graph.microsoft.com/v1.0/sites/ac0e0d27-bebd-43c8-9990-28adb4ddce41/drive/root:/${file.name}:/content`;

    let result = await axios.put(uploadUrl, file, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": file.type,
      },
    });

    console.log(result);
  };

  const handleGetApplications = () => {
    getApplications().then((response) => {
      console.log(response.data.data);
      const options = response?.data?.data?.map((data) => ({
        value: data.ID,
        label: data.ACK_NO,
      }));
      // setApplicationList(options);
    });
    setApplicationList([{ label: "FAAS", value: "FAAS" }]);
  };

  const handleGetListAdminNo = async (search = "") => {
    try {
      setSelectedAdmNo(null);
      setStudentList([]);

      const params = {
        student_id: search,
        studstatus: "",
        transfer_tag: "N",
        page: 1,
        limit: 20,
        search: "",
        sort: "id_no",
      };

      const response = await getStudentList(params);
      if (response?.data?.data?.length > 0) {
        setStudentList(
          response?.data?.data?.map((item) => ({
            label: item?.admNo + " - " + item?.name,
            value: item?.admNo,
            studentName: item?.name,
          }))
        );
      } else {
        const params = {
          student_id: "",
          studstatus: "",
          transfer_tag: "N",
          page: 1,
          limit: 20,
          search: search,
          sort: "id_no",
        };
        const response = await getStudentList(params);
        setStudentList(
          response?.data?.data?.map((item) => ({
            label: item?.admNo + " - " + item?.name,
            value: item?.admNo,
            studentName: item?.name,
          }))
        );
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleSearch = (searchFunction = null, value = "") => {
    clearTimeout(refTimeoutSearch.current);

    if (value) {
      refTimeoutSearch.current = setTimeout(() => {
        searchFunction(value);
      }, 500);
    }
  };

  const getAdmOptions = () => {
    getStudentList().then((resp) => {
      if (resp?.response?.data?.message) {
        if (
          resp?.response?.data?.message?.length > 0 &&
          Array.isArray(resp?.response?.data?.message)
        ) {
          resp?.response?.data?.message?.map((contentErr) => {
            toast.error(contentErr?.msg);
          });
        } else {
          toast.error(resp?.response?.data?.message);
        }
      } else if (resp?.response?.status === 404) {
        toast.error("Data not found");
      } else {
        let listOption = [];
        resp?.data?.data?.map((item) => {
          listOption.push({
            value: item?.admNo,
            label: item?.admNo + " - " + item?.name,
            studentName: item?.name,
          });
        });
        setStudentList(listOption);
      }
    });
  };

  const handleGetStudentFileList = async () => {
    const studentFileList = await getStudentFileList();
    console.log(studentFileList);
    setUploadList(
      studentFileList?.data?.data?.map((item) => ({
        label: item?.docName + " - " + item?.applnName,
        value: item?.docId,
      }))
    );
  };

  const handleGetData = () => {
    console.log("akakakakk");
  };

  useEffect(() => {
    //get the list a b c
    handleGetApplications();
    getAdmOptions();
    handleGetStudentFileList();
  }, []);

  return (
    <Layout currentNav={"utilities"}>
      <Breadcrumb
        listProps={{
          className: "bg-light",
          foo: "bar",
        }}
      >
        <Breadcrumb.Item>Utilities</Breadcrumb.Item>
        <Breadcrumb.Item active>Upload Student File</Breadcrumb.Item>
      </Breadcrumb>

      <sgds-content-header-top>
        <h2>Upload Student File</h2>
      </sgds-content-header-top>
      <sgds-content-body>
        <div className="d-flex gap-4 flex-column">
          <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
            <div className="row">
              <div className="col">
                <h3>Data Retrieval</h3>
              </div>
            </div>

            <Form
              ref={contentRef}
              className={` d-flex flex-column gap-3 collapse-animation`}
              style={{
                overflow: isOpen ? "unset" : "hidden",
                maxHeight: isOpen
                  ? `${
                      contentRef?.current?.scrollHeight == null
                        ? "100%"
                        : contentRef?.current?.scrollHeight
                    }px`
                  : "0px",
              }}
              // onSubmit={() => console.log("a")}
            >
              <Row>
                <Col xs={6}>
                  <Form.Group>
                    <Form.Label>Adm No.</Form.Label>
                    <Select
                      options={studentList}
                      onChange={(option) => {
                        setSelectedAdmNo(option.value);
                        setSelectedName(option.studentName);
                      }}
                      onInputChange={(value) =>
                        handleSearch(handleGetListAdminNo, value)
                      }
                      isClearable={true}
                    ></Select>
                  </Form.Group>
                </Col>

                <Col xs={6}>
                  <Form.Group>
                    <Form.Label>Application ID</Form.Label>
                    <Select
                      options={applicationList}
                      onChange={(option) => setSelectedAppId(option.value)}
                      isClearable={true}
                    ></Select>
                  </Form.Group>
                </Col>

                <Col xs={6}>
                  <Form.Group>
                    <Form.Label>Document ID</Form.Label>
                    <Select
                      options={uploadList}
                      onChange={(option) => setSelectedDocId(option.value)}
                      isClearable={true}
                    ></Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={3}>
                  <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleGetData()}
                  >
                    Retrieve
                  </Button>
                </Col>
              </Row>
            </Form>

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="btn-clear btn-link ps-0 mt-3 d-flex gap-2 align-items-center"
            >
              {isOpen ? "Hide " : "Expand "}{" "}
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </div>
        </div>

        <h3>{selectedName ?? "-"}</h3>

        <div className={`p-3 bg-white shadow-sm rounded`}>
          <h4 className={`text-blue my-3`}>Upload Student File</h4>
          <div className="px-8 py-3">
            <div {...getRootProps({ className: "drop" })}>
              <input {...getInputProps()} />
              <i
                className={`bi bi-cloud-upload`}
                style={{ fontSize: "30px" }}
              ></i>
              <div>Drag and drop or choose a file to upload</div>
              <div className={`text-muted fst-italic mt-3`}>
                Please upload relevant supporting documents in gif, jpeg, png,
                doc, docx or pdf format. File size cannot exceed 5 MB
              </div>

              <div
                className={`btn btn-primary mt-3 d-flex gap-2 align-items-center`}
              >
                <i className={`bi bi-upload`} style={{ fontSize: "20px" }}></i>
                Choose a File
              </div>
            </div>

            {selectedFile && (
              <div className="d-flex gap-2 align-items-center mt-3">
                <i className={`bi bi-check-lg text-green-500`}></i>
                {selectedFile.path}
                <button className={`btn-clear`} onClick={() => setSelectedFile(null)}>
                  <i className={`bi bi-x-circle text-red-500`}></i>
                </button>
              </div>
            )}
          </div>
          <div className="d-flex gap-3 align-items-center mt-3">
            <Button variant="outline-dark" type={"button"}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type={"button"}
              onClick={onSubmitUploadFile}
            >
              Upload
            </Button>
          </div>
        </div>
      </sgds-content-body>
    </Layout>
  );
}
