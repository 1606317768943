import React from "react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Col, Form, Row } from "@govtechsg/sgds-react";

function WrapperSwiperHomes({ bodyTab, setBodyTab }) {
  const handleClickPersonalParticulars = () => {
    setBodyTab({
      PersonalParticulars: true,
      Application: false,
      FamilyDetails: false,
      ResultDetails: false,
      OtherDetails: false,
      OriginalDetails: false,
      MTRequestDetails: false,
      Appeal: false,
    });
  };
  const handleClickApplication = () => {
    setBodyTab({
      PersonalParticulars: false,
      Application: true,
      FamilyDetails: false,
      ResultDetails: false,
      OtherDetails: false,
      OriginalDetails: false,
      MTRequestDetails: false,
      Appeal: false,
    });
  };
  const handleClickFamilyDetails = () => {
    setBodyTab({
      PersonalParticulars: false,
      Application: false,
      FamilyDetails: true,
      ResultDetails: false,
      OtherDetails: false,
      OriginalDetails: false,
      MTRequestDetails: false,
      Appeal: false,
    });
  };
  const handleClickResultDetails = () => {
    setBodyTab({
      PersonalParticulars: false,
      Application: false,
      FamilyDetails: false,
      ResultDetails: true,
      OtherDetails: false,
      OriginalDetails: false,
      MTRequestDetails: false,
      Appeal: false,
    });
  };
  const handleClickOtherDetails = () => {
    setBodyTab({
      PersonalParticulars: false,
      Application: false,
      FamilyDetails: false,
      ResultDetails: false,
      OtherDetails: true,
      OriginalDetails: false,
      MTRequestDetails: false,
      Appeal: false,
    });
  };
  const handleClickOriginalDetails = () => {
    setBodyTab({
      PersonalParticulars: false,
      Application: false,
      FamilyDetails: false,
      ResultDetails: false,
      OtherDetails: false,
      OriginalDetails: true,
      MTRequestDetails: false,
      Appeal: false,
    });
  };

  const handleClickMTRequestDetails = () => {
    setBodyTab({
      PersonalParticulars: false,
      Application: false,
      FamilyDetails: false,
      ResultDetails: false,
      OtherDetails: false,
      OriginalDetails: false,
      MTRequestDetails: true,
      Appeal: false,
    });
  };
  const handleClickAppeal = () => {
    setBodyTab({
      PersonalParticulars: false,
      Application: false,
      FamilyDetails: false,
      ResultDetails: false,
      OtherDetails: false,
      OriginalDetails: false,
      MTRequestDetails: false,
      Appeal: true,
    });
  };

  return (
    <div className="wrapper-swiper">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        navigation={{
          prevEl: ".prev-custom",
          nextEl: ".next-custom",
        }}
        breakpoints={{
          965: {
            slidesPerView: 2,
          },
          1135: {
            slidesPerView: 3,
          },
          1477: {
            slidesPerView: 5,
          },
        }}
        modules={[Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Button
            className={`${bodyTab.PersonalParticulars ? "active" : ""}`}
            onClick={handleClickPersonalParticulars}
          >
            Personal Particulars
          </Button>
        </SwiperSlide>
        <SwiperSlide>
          <Button
            className={`${bodyTab.Application ? "active" : ""}`}
            onClick={handleClickApplication}
          >
            Application
          </Button>{" "}
        </SwiperSlide>
        <SwiperSlide>
          <Button
            className={`${bodyTab.FamilyDetails ? "active" : ""}`}
            onClick={handleClickFamilyDetails}
          >
            Family Details
          </Button>{" "}
        </SwiperSlide>
        <SwiperSlide>
          <Button
            className={`${bodyTab.OtherDetails ? "active" : ""}`}
            onClick={handleClickOtherDetails}
          >
            Scholarship & Others
          </Button>{" "}
        </SwiperSlide>
        <SwiperSlide>
          <Button
            className={`${bodyTab.OriginalDetails ? "active" : ""}`}
            onClick={handleClickOriginalDetails}
          >
            Original Details
          </Button>
        </SwiperSlide>
        <SwiperSlide>
          <Button
            className={`${bodyTab.MTRequestDetails ? "active" : ""}`}
            onClick={handleClickMTRequestDetails}
          >
            MT Request Details
          </Button>
        </SwiperSlide>
        <SwiperSlide>
          <Button
            className={`${bodyTab.Appeal ? "active" : ""}`}
            onClick={handleClickAppeal}
          >
            Appeal
          </Button>
        </SwiperSlide>
      </Swiper>
      <div class="prev-custom">
        <svg
          width="14"
          height="24"
          viewBox="0 0 14 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.1928 0.271157C13.2787 0.356893 13.3469 0.458743 13.3934 0.570875C13.44 0.683007 13.4639 0.803216 13.4639 0.924619C13.4639 1.04602 13.44 1.16623 13.3934 1.27836C13.3469 1.39049 13.2787 1.49234 13.1928 1.57808L2.76876 12.0002L13.1928 22.4224C13.3661 22.5957 13.4634 22.8308 13.4634 23.0759C13.4634 23.321 13.3661 23.556 13.1928 23.7293C13.0195 23.9026 12.7844 24 12.5393 24C12.2942 24 12.0592 23.9026 11.8858 23.7293L0.810219 12.6537C0.724266 12.568 0.656071 12.4661 0.609542 12.354C0.563012 12.2419 0.539062 12.1216 0.539062 12.0002C0.539062 11.8788 0.563012 11.7586 0.609542 11.6465C0.656071 11.5344 0.724266 11.4325 0.810219 11.3468L11.8858 0.271157C11.9716 0.185204 12.0734 0.11701 12.1856 0.0704803C12.2977 0.0239508 12.4179 0 12.5393 0C12.6607 0 12.7809 0.0239508 12.893 0.0704803C13.0052 0.11701 13.107 0.185204 13.1928 0.271157Z"
            fill="#50504F"
          />
        </svg>
      </div>
      <div class="next-custom">
        <svg
          width="14"
          height="24"
          viewBox="0 0 14 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.810219 0.271157C0.895955 0.185204 0.997805 0.11701 1.10994 0.0704803C1.22207 0.0239508 1.34228 0 1.46368 0C1.58508 0 1.70529 0.0239508 1.81742 0.0704803C1.92956 0.11701 2.03141 0.185204 2.11714 0.271157L13.1928 11.3468C13.2787 11.4325 13.3469 11.5344 13.3934 11.6465C13.44 11.7586 13.4639 11.8788 13.4639 12.0002C13.4639 12.1216 13.44 12.2419 13.3934 12.354C13.3469 12.4661 13.2787 12.568 13.1928 12.6537L2.11714 23.7293C1.94383 23.9026 1.70878 24 1.46368 24C1.21859 24 0.983528 23.9026 0.810219 23.7293C0.63691 23.556 0.539546 23.321 0.539546 23.0759C0.539546 22.8308 0.63691 22.5957 0.810219 22.4224L11.2342 12.0002L0.810219 1.57808C0.724266 1.49234 0.656071 1.39049 0.609542 1.27836C0.563012 1.16623 0.539062 1.04602 0.539062 0.924619C0.539062 0.803216 0.563012 0.683007 0.609542 0.570875C0.656071 0.458743 0.724266 0.356893 0.810219 0.271157Z"
            fill="#50504F"
          />
        </svg>
      </div>
    </div>
  );
}

export default WrapperSwiperHomes;
