import React, {useEffect} from "react";
import {Col, Form, FormCheck, Row, Table,} from "@govtechsg/sgds-react";
import {Accordion} from "@govtechsg/sgds-react/Accordion";

function OtherDetails({isDisabled, details, studentDetail, onDataChange, handleApplicationChange, activeKeys, handleToggleAccordion}) {

    const roleList = JSON.parse(sessionStorage.getItem('roles')) || [];

    useEffect(() => {

    }, [details])



    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>Sponsor</Form.Label>
                            <Form.Control type="text" disabled={true}
                                        //   value={""}
                                // onChange={(e) => handleChange("COMP_SPONSOR_TAG", e.target.value)}
                                value={details?.comp_sponsor_tag ?? ""}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group>
                            <Form.Label>Tuition Fee Loan</Form.Label>
                            <Form.Control type="text" disabled={true} value={studentDetail?.tutFeeLoan ?? ""}></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group>
                            <Form.Label>Mendaki Tertiary Fees Subsidy (%)</Form.Label>
                            <Form.Control type="text" disabled={true}
                                          value={studentDetail?.mendakiSubsidy ?? ""}></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </div>

            <div className="mt-4">
                <Accordion key={"11"} activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="11" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("11")}>
                            Award History
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Table responsive>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Award Type</Table.HeaderCell>
                                                    <Table.HeaderCell>Award</Table.HeaderCell>
                                                    <Table.HeaderCell>Year of Award</Table.HeaderCell>
                                                    <Table.HeaderCell>Award Amount</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {studentDetail?.studFinAssistList?.map((award) => (
                                                    <Table.Row>
                                                        <Table.DataCell>{award?.awardTypeDesc}</Table.DataCell>
                                                        <Table.DataCell>{award?.awardDesc}</Table.DataCell>
                                                        <Table.DataCell>{award?.yrAwarded}</Table.DataCell>
                                                        <Table.DataCell>{award?.amtAwarded}</Table.DataCell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="mt-4">
                <Accordion key={"12"}activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="12" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("12")}>
                            Charged-in-court
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <FormCheck
                                                id="Charged_in_a_court_of_law_in_any_country"
                                                label="Charged in a court of law in any country"
                                                name="Charged_in_a_court_of_law_in_any_country "
                                                type="checkbox"
                                                checked={details?.charge_in_court === "Y"}
                                                disabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                                onChange={(e) => handleApplicationChange("charge_in_court", e.target.checked ? "Y" : "")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="mt-4">
                <Accordion key={"13"}activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="13" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("13")}>
                            Application Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                disabled={isDisabled}
                                                onChange={(e) => handleApplicationChange("remarks1", e.target.value)}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="mt-4">
                <Accordion key={"14"} activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="14" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("14")}>
                            Summary Assessment
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <FormCheck
                                                id="CPF_approved_funds"
                                                label="CPF Approved Funds"
                                                name="CPF_approved_funds "
                                                type="checkbox"
                                                disabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                                checked={details?.cpf_approved_tag === "Y"}
                                                onChange={(e) => handleApplicationChange("cpf_approved_tag", e.target.checked ? "Y" : "")}
                                            />
                                            <FormCheck
                                                id="Intact_family"
                                                label="Intact Family"
                                                name="intact_family "
                                                type="checkbox"
                                                disabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                                checked={details?.intact_family_tag === "Y"}
                                                onChange={(e) => handleApplicationChange("intact_family_tag", e.target.checked ? "Y" : "")}
                                            />
                                            <FormCheck
                                                id="company_sponsored"
                                                label="Company Sponsored"
                                                name="company_sponsored "
                                                type="checkbox"
                                                disabled={isDisabled}
                                                checked={details?.comp_sponsor_tag === "Y"}
                                                onChange={(e) => handleApplicationChange("comp_sponsor_tag", e.target.checked ? "Y" : "")}
                                            />
                                            <FormCheck
                                                id="playing_moe_subsidised_fees"
                                                label="Playing MOE Subsidised Fees"
                                                name="playing_moe_subsidised_fees "
                                                type="checkbox"
                                                disabled={isDisabled}
                                                checked={details?.moe_subsidised === "Y"}
                                                onChange={(e) => handleApplicationChange("moe_subsidised", e.target.checked ? "Y" : "")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Recommended in NPPS</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.NPPS ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Recommended in FASOP</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.FASOP ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </React.Fragment>
    );
}

export default OtherDetails;
