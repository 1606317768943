import { apiCall } from "@/utils/apiCall";
import {createUrlWithParams} from "@/components/utils/utils";

export function getParents(ACAD_YR, EXERCISE, ADM_NO) {
  let params = {};
  if (ACAD_YR !== null && ACAD_YR !== undefined) {
    params = {
      ...params,
      acad_yr: ACAD_YR,
    };
  }
  if (EXERCISE !== null && EXERCISE !== undefined) {
    params = {
      ...params,
      exercise: EXERCISE,
    };
  }
  if (ADM_NO !== null && ADM_NO !== undefined) {
    params = {
      ...params,
      adm_no: ADM_NO,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/parents`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export function updateParents(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/parents`,
    params,
    "PATCH"
  );
}
