import { apiCall } from "@/utils/apiCall";

// NYP

// {
//     "CODE": "10",
//     "REMARK": "need confirmations"
// }

export async function getRemarkNYP(limit = 20, page = 1) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp?limit=${limit}&page=${page}`,
    {},
    "GET"
  );
}

export function postRemarkNYP(remarkData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp`,
    remarkData,
    "POST"
  );
}

export function putRemarkNYP(remarkID, remarkData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp/${remarkID}`,
    remarkData,
    "PUT"
  );
}

export function deleteRemarkNYP(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp/${selectedId}`,
    {},
    "DELETE"
  );
}

export function postRemarkNYPOverwrite(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp/overwrite`,
    {
      data: jsonData,
    },
    "POST"
  );
}

export function postRemarkNYPAppend(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp/append`,
    {
      data: jsonData,
    },
    "POST"
  );
}

// PA

// {
//     "STS_REASON": "withdraw"
// }

export async function getRemarkPA(limit = 20, page = 1) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa?limit=${limit}&page=${page}`,
    {},
    "GET"
  );
}

export function postRemarkPA(remarkData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa`,
    remarkData,
    "POST"
  );
}

export function putRemarkPA(remarkID, remarkData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa/${remarkID}`,
    remarkData,
    "PUT"
  );
}

export function deleteRemarkPA(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa/${selectedId}`,
    {},
    "DELETE"
  );
}

export function postRemarkPAOverwrite(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa/overwrite`,
    {
      data: jsonData,
    },
    "POST"
  );
}

export function postRemarkPAAppend(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa/append`,
    {
      data: jsonData,
    },
    "POST"
  );
}
