import {Accordion, Col, Form, Row, Table} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import {postAppeal} from "@/apis/app-records/homes/api";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import CommonModal from "@/components/CommonModal";
import {toast} from "react-toastify";
import Select from "react-select";

const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"},
];

const genderOptions = [
    {value: "M", label: "Male"},
    {value: "F", label: "Female"},
];

function FamilyDetails({
                           isDisabled,
                           applicationDetail,
                           listMTCRequestResult,
                           listMTCDetails,
                           listMTIncomes,
                           listMTConsents,
                           listMTCases,
                           listMTCaseNotes,
                           listMTCDetailsOriginal,
                           listOriginalDetails,
                           listSiblingInNYP,
                           handleClickEdit,
                           ACK_NO,
                           ACK_YR,
                           selectedListExercise,
                           selectedListAdmNo,
                           details,
                           setDetails,
                           specialMeansStatusOptions,
                           residentialOptions,
                           maritalStatusOptions,
                           consentTypeOptions,
                           consentStatusOptions,
                           consentScopeOptions,
                           legalCapacityOptions,
                           relationshipOptions,
                           occupationOptions,
                           listMTCDetailsA,
                           setListMTCDetailsA,
                           activeKeys,
                           handleToggleAccordion,
                           handleApplicationChange
                       }) {
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(-1);
    let counter = 0;

    const handleAddFamily = () => {
        const newDetail = {
            id: "",
            detail_tag: "A",
            ack_no: applicationDetail?.ack_no,
            acad_yr: ACK_YR,
            faas_exercise: selectedListExercise,
            nbfa_exercise: "",
            adm_no: selectedListAdmNo,
            hh_uin: "",
            hh_name: "",
            other_income: "",
            marital_status: "",
            res_status: "",
            dob: "",
            gender: "",
            mtc_tag: "A",
            relationship: "",
            special_means_status: "",
            isnovalidauth: "",
            remarks: "",
            mtrequestid: "",
            means_test_id: "",
            status_tag: "",
            change_type: "",
            income_period: "",
            avg_mthly_income: "",
            mt_member_indicators: "",
            occupation: "",
            reusable_consentid: "",
            reusable_consentscope: "",
            last_upd_id: sessionStorage.getItem("staffId")
                ? sessionStorage.getItem("staffId")
                : process.env.REACT_APP_LAST_UPD_ID,
            // mt_consent_details: [{
            //     id: "",
            //     detail_tag: "",
            //     ack_no: "",
            //     hh_uin: "",
            //     consent_type: "",
            //     consent_scope: "",
            //     nyp_consent_id: "",
            //     nyp_consent_name: "",
            //     nyp_consent_email: "",
            //     nyp_consent_status: "",
            //     nyp_consent_date: "",
            //     nyp_manual_consent: "",
            //     rec_create_date: "",
            //     last_upd_id: sessionStorage.getItem("staffId")
            //         ? sessionStorage.getItem("staffId")
            //         : process.env.REACT_APP_LAST_UPD_ID,
            //     legal_capacity: "",
            // }],
            mt_consent_details: []
        };

        setListMTCDetailsA([...listMTCDetailsA, newDetail]);
    };

    const handleRemoveFamily = (index, familyID) => {
        if (familyID) {
            postAppeal([
                {
                    ...listMTCDetailsA[index],
                    // ID: familyID,
                    dob: moment(listMTCDetailsA[index]?.dob).format("YYYY-MM-DD"),
                    mtc_tag: "D",
                },
            ]).then((response) => {
                console.log(response);
                // setDeleteModal(false);
                setListMTCDetailsA((prevState) => {
                    return prevState.filter((_, i) => i !== index);
                });

                setDeleteModal(false);
            });
        } else {
            setListMTCDetailsA((prevState) => {
                return prevState.filter((_, i) => i !== index);
            });

            setDeleteModal(false);

        }

    };

    const handleAddConsent = (index, hh_uin, ack_no) => {
        const newConsent = {
            id: "",
            detail_tag: "A",
            ack_no: applicationDetail?.ack_no,
            hh_uin: hh_uin,
            consent_type: "",
            consent_scope: "",
            nyp_consent_id: "",
            nyp_consent_name: "",
            nyp_consent_email: "",
            nyp_consent_status: "",
            nyp_consent_date: "",
            nyp_manual_consent: "",
            rec_create_date: "",
            last_upd_id: sessionStorage.getItem("staffId")
                ? sessionStorage.getItem("staffId")
                : process.env.REACT_APP_LAST_UPD_ID,
            legal_capacity: "",
        };
        const updatedDetails = listMTCDetailsA.map((detail, i) => {
            if (i === index) {
                return {
                    ...detail,
                    mt_consent_details: [...detail.mt_consent_details, newConsent],
                };
            }
            return detail;
        });

        setListMTCDetailsA(updatedDetails);
    }

    const handleRemoveConsent = (detailIndex, consentIndex) => {
        const updatedDetails = listMTCDetailsA.map((detail, i) => {
            if (i === detailIndex) {
                return {
                    ...detail,
                    mt_consent_details: detail.mt_consent_details.filter((_, cIndex) => cIndex !== consentIndex),
                };
            }
            return detail;
        });

        setListMTCDetailsA(updatedDetails);
    }

    const handleChange = (detailIndex, field, value, consentIndex = 0) => {
        const updatedDetails = listMTCDetailsA.map((detail, i) => {
            if (i === detailIndex) {
                if (field.startsWith("mt_consent_details.")) {
                    const consentField = field.replace("mt_consent_details.", "");
                    return {
                        ...detail,
                        mt_consent_details: detail.mt_consent_details.map((consent, j) => {
                            if (j === consentIndex) {
                                return {
                                    ...consent,
                                    [consentField]: value,
                                };
                            }
                            return consent;
                        }),
                    };
                }
                return {
                    ...detail,
                    [field]: value,
                };
            }
            return detail;
        });

        setListMTCDetailsA(updatedDetails);
    };

    // const handleChange = (index, field, value) => {
    //     const updatedDetails = details.map((detail, i) => {
    //         if (i === index) {
    //             if (field.startsWith("MT_CONSENT_DETAILS.")) {
    //                 const consentField = field.replace("MT_CONSENT_DETAILS.", "");
    //                 return {
    //                     ...detail,
    //                     MT_CONSENT_DETAILS: {
    //                         ...detail.MT_CONSENT_DETAILS,
    //                         [consentField]: value,
    //                     },
    //                 };
    //             }
    //             return {
    //                 ...detail,
    //                 [field]: value,
    //             };
    //         }
    //         return detail;
    //     });
    //     setDetails(updatedDetails);
    // };


    useEffect(() => {
        /*
        const consentLookup = listMTConsents.reduce((acc, consent) => {
            acc[consent.ACK_NO] = {
                ID: consent?.ID,
                DETAIL_TAG: consent?.DETAIL_TAG,
                ACK_NO: consent?.ACK_NO,
                HH_UIN: consent?.HH_UIN,
                CONSENT_TYPE: consent?.CONSENT_TYPE,
                CONSENT_SCOPE: consent?.CONSENT_SCOPE,
                NYP_CONSENT_ID: consent?.NYP_CONSENT_ID,
                NYP_CONSENT_NAME: consent?.NYP_CONSENT_NAME,
                NYP_CONSENT_EMAIL: consent?.NYP_CONSENT_EMAIL,
                NYP_CONSENT_STATUS: consent?.NYP_CONSENT_STATUS,
                NYP_CONSENT_DATE: consent?.NYP_CONSENT_DATE,
                NYP_MANUAL_CONSENT: consent?.NYP_MANUAL_CONSENT,
                REC_CREATE_DATE: consent?.REC_CREATE_DATE,
                LAST_UPD_ID: consent?.LAST_UPD_ID,
                LEGAL_CAPACITY: consent?.LEGAL_CAPACITY,
            };
            return acc;
        }, {});

        const remapDetails = listMTCDetails?.map((detail) => {
            const consent = consentLookup[detail.ACK_NO] || {};

            return {
                ID: detail?.ID,
                DETAIL_TAG: detail?.DETAIL_TAG,
                ACK_NO: detail?.ACK_NO,
                ACAD_YR: detail?.ACAD_YR,
                FAAS_EXERCISE: detail?.FAAS_EXERCISE,
                NBFA_EXERCISE: detail?.NBFA_EXERCISE,
                ADM_NO: detail?.ADM_NO,
                HH_UIN: detail?.HH_UIN,
                HH_NAME: detail?.HH_NAME,
                OTHER_INCOME: detail?.OTHER_INCOME,
                MARITAL_STATUS: detail?.MARITAL_STATUS,
                RES_STATUS: detail?.RES_STATUS,
                DOB: detail?.DOB,
                GENDER: detail?.GENDER,
                MTC_TAG: detail?.MTC_TAG,
                RELATIONSHIP: detail?.RELATIONSHIP,
                SPECIAL_MEANS_STATUS: detail?.SPECIAL_MEANS_STATUS,
                ISNOVALIDAUTH: detail?.ISNOVALIDAUTH,
                REMARKS: detail?.REMARKS,
                MTREQUESTID: detail?.MTREQUESTID,
                MEANS_TEST_ID: detail?.MEANS_TEST_ID,
                STATUS_TAG: detail?.STATUS_TAG,
                CHANGE_TYPE: detail?.CHANGE_TYPE,
                INCOME_PERIOD: detail?.INCOME_PERIOD,
                AVG_MTHLY_INCOME: detail?.AVG_MTHLY_INCOME,
                MT_MEMBER_INDICATORS: detail?.MT_MEMBER_INDICATORS,
                OCCUPATION: detail?.OCCUPATION,
                REUSABLE_CONSENTID: detail?.REUSABLE_CONSENTID,
                REUSABLE_CONSENTSCOPE: detail?.REUSABLE_CONSENTSCOPE,
                LAST_UPD_ID: detail?.LAST_UPD_ID,
                MT_CONSENT_DETAILS: consent,
            };
        });

        setDetails(remapDetails);
        */
    }, [listMTCDetails, listMTConsents]);

    useEffect(() => {
        // listMTCRequestResult?.incomepointvalue_pci
        // listMTCRequestResult?.incomepointvalue_gi
        // applicationDetail?.family_members

        let mpci = 0;
        let ccc_income = 0;
        let countMembers = Number(listMTCDetailsA?.length ?? 0)
        listMTCDetailsA?.map((content, index) => {
            content?.mt_consent_details?.map((consent, index) => {
                handleChange(index, "mt_consent_details.nyp_consent_date", moment(consent?.nyp_consent_date).format("YYYY-MM-DD"));
            });

            ccc_income += Number(content?.avg_mthly_income) + Number(content?.other_income);
        });

        mpci = (ccc_income.toFixed(2) / countMembers).toFixed(2);

        handleApplicationChange("mpci", mpci);
        handleApplicationChange("ccc_income", ccc_income);
        handleApplicationChange("family_members", countMembers);

    }, [listMTCDetailsA]);

    return (
        <React.Fragment>
            <div className="">
                <Accordion key="8" activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="8" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("8")}>
                            Family Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Income Point Value PCI</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.mpci ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Income Point Value Gross Income</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.ccc_income ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Family Member</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.family_members ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Family Member Consent Status</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion key="9" activeKey={activeKeys}>
                    <Accordion.Item eventKey="9" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("9")}>
                            Family Members
                        </Accordion.Header>
                        <Accordion.Body>
                            <button
                                className={`btn-clear text-blue`}
                                onClick={handleAddFamily}
                                disabled={isDisabled}
                            >
                                <i className={`bi-plus-circle`}></i> Add family member
                            </button>
                            <div>
                                {listMTCDetailsA?.map((detail, index) => {
                                    return (
                                        <Row>
                                        <Col xs={12} className={`fw-bold`}>
                                            #{index + 1} Member{" "}
                                            {!isDisabled ? (
                                                <button
                                                    className={`btn-clear`}
                                                    onClick={() => {
                                                        setSelectedId(detail?.id);
                                                        setSelectedIndex(index);
                                                        setDeleteModal(true);
                                                    }}
                                                >
                                                    <i className={`bi-trash`}></i>
                                                </button>
                                            ) : detail.mtc_tag === "D" ? (
                                                <p className={`text-danger`}>Deleted</p>
                                            ) : (
                                                <p className={`text-danger`}>Added</p>
                                            )}
                                        </Col>
                                        <Col xs={12} className="">
                                            <Form.Group>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type={`text`}
                                                    disabled={isDisabled && detail.mtc_tag !== "D"}
                                                    value={detail?.hh_name ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(index, "hh_name", e.target.value)
                                                    }
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} className="">
                                            <Form.Group>
                                                <Form.Label>
                                                    NRIC/Fin/Passport No. (if foreigner)
                                                    <span className={`text-danger`}> *</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    disabled={isDisabled && detail.mtc_tag !== "D"}
                                                    value={detail?.hh_uin ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(index, "hh_uin", e.target.value)
                                                    }
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} className="">
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>Date of Birth</Form.Label>
                                                <DatePicker
                                                    selected={detail?.dob == undefined ? "" : detail?.dob}
                                                    className={`form-control`}
                                                    onChange={(date) => {
                                                        if (date == null) {
                                                            handleChange(index, "dob", "");
                                                        } else {
                                                            handleChange(
                                                                index,
                                                                "dob",
                                                                moment(date).format("YYYY-MM-DD")
                                                            );
                                                        }
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    disabled={isDisabled && detail.mtc_tag !== "D"}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} className="">
                                            <Form.Group>
                                                <Form.Label>Age</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={detail?.dob && Math.floor((new Date() - new Date(detail?.dob)) / (365.25 * 24 * 60 * 60 * 1000)) }
                                                    // disabled={true}
                                                    disabled={isDisabled && detail.mtc_tag !== "D"}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} className="">
                                            <Form.Group>
                                                <Form.Label>Gender</Form.Label>
                                                <Select
                                                    options={genderOptions}
                                                    isDisabled={isDisabled && detail.mtc_tag !== "D"}
                                                    value={{
                                                        value: detail?.gender,
                                                        label:
                                                            detail?.gender == "M" ? "Male" : "Female",
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        handleChange(
                                                            index,
                                                            "gender",
                                                            selectedOption.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} className="">
                                            <Form.Group>
                                                <Form.Label>Relationship Status</Form.Label>
                                                <Select
                                                    options={relationshipOptions}
                                                    isDisabled={isDisabled && detail.mtc_tag !== "D"}
                                                    value={relationshipOptions.find(option => option.value === detail?.relationship)}
                                                    // value={{
                                                    //     value: detail?.RELATIONSHIP,
                                                    //     label: detail?.RELATIONSHIP,
                                                    // }}
                                                    onChange={(selectedOption) => {
                                                        handleChange(
                                                            index,
                                                            "relationship",
                                                            selectedOption.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} className="">
                                            <Form.Group>
                                                <Form.Label>Residental Status</Form.Label>
                                                <Select
                                                    options={residentialOptions}
                                                    isDisabled={isDisabled && detail.mtc_tag !== "D"}
                                                    value={residentialOptions.find(option => option.value === detail?.res_status)}
                                                    onChange={(selectedOption) => {
                                                        handleChange(
                                                            index,
                                                            "res_status",
                                                            selectedOption.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} className="">
                                            <Form.Group>
                                                <Form.Label>Martial Status</Form.Label>
                                                <Select
                                                    options={maritalStatusOptions}
                                                    value={maritalStatusOptions.find(option => option.value === detail?.marital_status)}
                                                    isDisabled={isDisabled && detail.mtc_tag !== "D"}
                                                    onChange={(selectedOption) => {
                                                        handleChange(
                                                            index,
                                                            "marital_status",
                                                            selectedOption.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} className={`fw-bold my-3 text-blue`}>
                                            Income Details
                                        </Col>
                                        <Col xs={12} className="">
                                            <Form.Group>
                                                <Form.Label>Occupation</Form.Label>
                                                <Select
                                                    value={occupationOptions.find(option => option.value === detail?.occupation)}
                                                    options={occupationOptions}
                                                    isDisabled={isDisabled && detail.mtc_tag !== "D"}
                                                    onChange={(selectedOption) => {
                                                        handleChange(
                                                            index,
                                                            "occupation",
                                                            selectedOption.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} className="">
                                            <Form.Group>
                                                <Form.Label>Avg Monthly Income</Form.Label>
                                                <Form.Control
                                                    type={`text`}
                                                    disabled={isDisabled && detail.mtc_tag !== "D"}
                                                    value={detail?.avg_mthly_income ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "avg_mthly_income",
                                                            e.target.value
                                                        )
                                                    }
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6} className="">
                                            <Form.Group>
                                                <Form.Label>Other income</Form.Label>
                                                <Form.Control
                                                    type={`text`}
                                                    disabled={isDisabled && detail.mtc_tag !== "D"}
                                                    value={detail?.other_income ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "other_income",
                                                            e.target.value
                                                        )
                                                    }
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} className="">
                                            <Form.Group>
                                                <Form.Label>Remarks</Form.Label>
                                                <Form.Text className="text-muted">
                                                    Reason if occupation status is unemployed
                                                </Form.Text>
                                                <Form.Control
                                                    type={`text`}
                                                    disabled={isDisabled && detail.mtc_tag !== "D"}
                                                    value={detail?.remarks ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(index, "remarks", e.target.value)
                                                    }
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} className="">
                                            <Form.Group>
                                                <Form.Label>Special Means Status</Form.Label>
                                                <Select
                                                    isDisabled={isDisabled && detail.mtc_tag !== "D"}
                                                    options={specialMeansStatusOptions}
                                                    value={specialMeansStatusOptions.find(option => option.value === detail?.special_means_status)}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "special_means_status",
                                                            e.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} className={`fw-bold my-3 text-blue`}>
                                            Consent Details
                                        </Col>
                                        <Col xs={12} className="">
                                            <Form.Group>
                                                <Form.Label>Reusable Consent</Form.Label>
                                                <Form.Text className="text-muted">
                                                    If no, please specify Consent NRIC and Name
                                                </Form.Text>
                                                <Form.Control
                                                    type={`text`}
                                                    disabled={true}
                                                    value={detail?.reusable_consentscope ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "reusable_consentscope",
                                                            e.target.value
                                                        )
                                                    }
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} className="">
                                            <Form.Group>
                                                <Form.Label>Reusable Consent ID</Form.Label>
                                                <Form.Control
                                                    type={`text`}
                                                    disabled={true}
                                                    value={detail?.reusable_consentid ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "reusable_consentid",
                                                            e.target.value
                                                        )
                                                    }
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} className="">
                                            <Form.Group>
                                                <Form.Label>Reusable Consent Scope</Form.Label>
                                                <Select
                                                    isDisabled={true}
                                                    options={consentScopeOptions}
                                                    value={consentScopeOptions.find(option => option.value === applicationDetail?.reusable_consentscope)}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "reusable_consentscope",
                                                            e.value
                                                        )
                                                    }
                                                />
                                                {/*<Form.Control*/}
                                                {/*    type={`text`}*/}
                                                {/*    disabled={true}*/}
                                                {/*    value={detail?.REUSABLE_CONSENTSCOPE}*/}
                                                {/*    onChange={(e) =>*/}
                                                {/*        handleChange(*/}
                                                {/*            index,*/}
                                                {/*            "REUSABLE_CONSENTSCOPE",*/}
                                                {/*            e.target.value*/}
                                                {/*        )*/}
                                                {/*    }*/}
                                                {/*></Form.Control>*/}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} className="">
                                            <Form.Group>
                                                <Form.Label>Is No Valid Auth</Form.Label>
                                                <Form.Control
                                                    type={`text`}
                                                    disabled={true}
                                                    value={detail?.isnovalidauth ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "isnovalidauth",
                                                            e.target.value
                                                        )
                                                    }
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <h5 className={`mt-5`}>Consent Detail</h5>

                                        <div>
                                            <button
                                                className={`btn-clear text-blue`}
                                                onClick={() => handleAddConsent(index, detail?.hh_uin, detail?.ack_no)}
                                                disabled={isDisabled}
                                            >
                                                <i className={`bi-plus-circle`}></i> Add consent detail
                                            </button>
                                        </div>

                                        {detail?.mt_consent_details?.map((condetail, detailIndex) => (
                                            <Row key={detailIndex}>
                                                <Col xs={12}>
                                                    Consent Detail #{detailIndex + 1}
                                                    <button
                                                        className={`btn-clear text-red-500`}
                                                        onClick={() => handleRemoveConsent(index, detailIndex)}
                                                        disabled={isDisabled}
                                                    >
                                                        <i className={`bi-dash-circle`}></i> Remove consent detail
                                                    </button>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Consent Type</Form.Label>
                                                        <Select
                                                            isDisabled={
                                                                // condetail?.NYP_MANUAL_CONSENT === "Y" ||
                                                                isDisabled && detail.mtc_tag !== "D"
                                                            }
                                                            options={consentTypeOptions}
                                                            value={consentTypeOptions.find(option => option.value === condetail?.consent_type)}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "mt_consent_details.consent_type",
                                                                    e.value,
                                                                    detailIndex
                                                                )
                                                            }
                                                        />
                                                        {/*<Form.Control*/}
                                                        {/*    type={`text`}*/}
                                                        {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                        {/*    value={detail?.MT_CONSENT_DETAILS?.CONSENT_TYPE}*/}
                                                        {/*    onChange={(e) =>*/}
                                                        {/*        handleChange(*/}
                                                        {/*            index,*/}
                                                        {/*            "MT_CONSENT_DETAILS.CONSENT_TYPE",*/}
                                                        {/*            e.target.value*/}
                                                        {/*        )*/}
                                                        {/*    }*/}
                                                        {/*></Form.Control>*/}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Consent Scope</Form.Label>
                                                        <Select
                                                            isDisabled={
                                                                // condetail?.NYP_MANUAL_CONSENT === "Y" ||
                                                                isDisabled && detail.mtc_tag !== "D"
                                                            }
                                                            options={consentScopeOptions}
                                                            value={consentScopeOptions.find(option => option.value === condetail?.consent_scope)}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "mt_consent_details.consent_scope",
                                                                    e.value,
                                                                    detailIndex
                                                                )
                                                            }
                                                        />
                                                        {/*<Form.Control*/}
                                                        {/*    type={`text`}*/}
                                                        {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                        {/*    value={detail?.MT_CONSENT_DETAILS?.CONSENT_SCOPE}*/}
                                                        {/*    onChange={(e) =>*/}
                                                        {/*        handleChange(*/}
                                                        {/*            index,*/}
                                                        {/*            "MT_CONSENT_DETAILS.CONSENT_SCOPE",*/}
                                                        {/*            e.target.value*/}
                                                        {/*        )*/}
                                                        {/*    }*/}
                                                        {/*></Form.Control>*/}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Legal Capacity</Form.Label>
                                                        <Select
                                                            isDisabled={
                                                                // condetail?.NYP_MANUAL_CONSENT === "Y" ||
                                                                isDisabled && detail.mtc_tag !== "D"
                                                            }
                                                            options={legalCapacityOptions}
                                                            value={legalCapacityOptions.find(option => option.value === condetail?.legal_capacity)}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "mt_consent_details.legal_capacity",
                                                                    e.value,
                                                                    detailIndex
                                                                )
                                                            }
                                                        />
                                                        {/*<Form.Control*/}
                                                        {/*    type={`text`}*/}
                                                        {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                        {/*    value={*/}
                                                        {/*        detail?.MT_CONSENT_DETAILS?.LEGAL_CAPACITY*/}
                                                        {/*    }*/}
                                                        {/*    onChange={(e) =>*/}
                                                        {/*        handleChange(*/}
                                                        {/*            index,*/}
                                                        {/*            "MT_CONSENT_DETAILS.LEGAL_CAPACITY",*/}
                                                        {/*            e.target.value*/}
                                                        {/*        )*/}
                                                        {/*    }*/}
                                                        {/*></Form.Control>*/}
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Manual Consent</Form.Label>
                                                        <Select
                                                            isDisabled={
                                                                // condetail?.NYP_MANUAL_CONSENT === "Y" ||
                                                                isDisabled && detail.mtc_tag !== "D"
                                                            }
                                                            options={consentStatusOptions}
                                                            value={consentStatusOptions.find(option => option.value === condetail?.nyp_manual_consent)}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "mt_consent_details.nyp_manual_consent",
                                                                    e.value,
                                                                    detailIndex
                                                                )
                                                            }
                                                        />

                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Consent NRIC</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled && detail.mtc_tag !== "D"}
                                                            value={
                                                                condetail?.nyp_consent_id ?? ""
                                                            }
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "mt_consent_details.nyp_consent_id",
                                                                    e.target.value,
                                                                    detailIndex
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Consent Name</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled && detail.mtc_tag !== "D"}
                                                            value={
                                                                condetail?.nyp_consent_name ?? ""
                                                            }
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "mt_consent_details.nyp_consent_name",
                                                                    e.target.value,
                                                                    detailIndex
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Consent Email</Form.Label>
                                                        <Form.Control
                                                            type={`email`}
                                                            disabled={isDisabled && detail.mtc_tag !== "D"}
                                                            value={
                                                                condetail?.nyp_consent_email ?? ""
                                                            }
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "mt_consent_details.nyp_consent_email",
                                                                    e.target.value,
                                                                    detailIndex
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Consent Status</Form.Label>
                                                        <Select
                                                            isDisabled={
                                                                // condetail?.NYP_MANUAL_CONSENT === "Y" ||
                                                                isDisabled && detail.mtc_tag !== "D"
                                                            }
                                                            options={consentStatusOptions}
                                                            value={consentStatusOptions.find(option => option.value === condetail?.nyp_consent_status)}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "mt_consent_details.nyp_consent_status",
                                                                    e.value,
                                                                    detailIndex
                                                                )
                                                            }
                                                        />
                                                        {/*<Form.Control*/}
                                                        {/*    type={`text`}*/}
                                                        {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                        {/*    value={*/}
                                                        {/*        detail?.MT_CONSENT_DETAILS?.NYP_CONSENT_STATUS*/}
                                                        {/*    }*/}
                                                        {/*    onChange={(e) =>*/}
                                                        {/*        handleChange(*/}
                                                        {/*            index,*/}
                                                        {/*            "MT_CONSENT_DETAILS.NYP_CONSENT_STATUS",*/}
                                                        {/*            e.target.value*/}
                                                        {/*        )*/}
                                                        {/*    }*/}
                                                        {/*></Form.Control>*/}
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group className="d-flex flex-column">
                                                        <Form.Label>Consent Date</Form.Label>
                                                        <DatePicker
                                                            selected={moment(condetail?.nyp_consent_date, "YYYY-MM-DD", true).isValid() ? condetail.nyp_consent_date : null}
                                                            className={`form-control`}
                                                            onChange={(date) => {
                                                                if (date === null) {
                                                                    handleChange(index, "mt_consent_details.nyp_consent_date", "");
                                                                } else {
                                                                    handleChange(
                                                                        index,
                                                                        "mt_consent_details.nyp_consent_date",
                                                                        moment(date).format("YYYY-MM-DD")
                                                                    );
                                                                }
                                                            }}
                                                            dateFormat="dd/MM/yyyy"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            disabled={isDisabled && detail.mtc_tag !== "D"}
                                                        />

                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        ))}

                                    </Row>

                                    )
                                })}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion key="10" activeKey={activeKeys}>
                    <Accordion.Item eventKey="10" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("10")}>
                            Siblings Studying in NYP
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12} className="">
                                        <Table responsive>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>#</Table.HeaderCell>
                                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                                    <Table.HeaderCell>Adm. No.</Table.HeaderCell>
                                                    <Table.HeaderCell>Course</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {listSiblingInNYP?.familyStudyInNYPList?.map(
                                                    (sibling, index) => (
                                                        <Table.Row key={index}>
                                                            <Table.DataCell>{index + 1}</Table.DataCell>
                                                            <Table.DataCell>{sibling.name}</Table.DataCell>
                                                            <Table.DataCell>{sibling.admNo}</Table.DataCell>
                                                            <Table.DataCell>
                                                                {sibling.courseCode} - {sibling.courseName}
                                                            </Table.DataCell>
                                                        </Table.Row>
                                                    )
                                                )}
                                            </Table.Body>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <CommonModal
                show={deleteModal}
                handleClose={() => setDeleteModal(false)}
                description={`Are you sure you want to delete family member?`}
                title={`Delete family member?`}
                submitButtonText={"Yes"}
                action={() => handleRemoveFamily(selectedIndex, selectedId)}
            />
        </React.Fragment>
    );
}

export default FamilyDetails;
