import React, {useEffect} from "react";

import {Col, Form, Row,} from "@govtechsg/sgds-react";


function Applicant({
                       data,
                       setShowFormulaModal,
                       isDisabled,
                       handleApplicationChange,
                       getTotalSumIncome,
                       isReadOnly = false
                   }) {

    useEffect(() => {
    }, [data])



    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Applicant's Gross Monthly Income</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={isReadOnly?true:isDisabled}
                                value={data?.mthly_income ?? ""}
                                onChange={(e) => {
                                    handleApplicationChange("mthly_income", e.target.value);
                                    getTotalSumIncome(e.target.value, null)
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Applicant's Other Income</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={isReadOnly?true:isDisabled}
                                value={data?.other_income ?? ""}
                                onChange={(e) => {
                                    handleApplicationChange("other_income", e.target.value);
                                    getTotalSumIncome(null, e.target.value)
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    {/*
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label className={`mt-1`}>Monthly Contribution</Form.Label>
                            <Form.Text className="text-muted lh-1 mt-0">
                                by financially independent siblings NOT living in the same house
                            </Form.Text>
                            <Form.Control
                                type={`text`}
                                disabled={isDisabled}
                                value={data?.INDEPEND_MTHLY_CONTRIB}
                                onChange={(e) => {
                                    handleApplicationChange(
                                        "INDEPEND_MTHLY_CONTRIB",
                                        e.target.value
                                    );
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                     */}
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label className={`mt-1`}>Number of Family </Form.Label>
                            <Form.Text className="text-muted lh-1 mt-0">
                                Family members living in the same household
                            </Form.Text>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.family_members ?? ""}
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label className={`mt-1`}>GHI - Gross Monthly Household Income</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.ccc_income ?? ""}
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label className={`mt-1`}>MPCI</Form.Label>
                            <Form.Text className="text-muted lh-1 mt-0">
                                Monthly per Capital Income
                            </Form.Text>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.mpci ?? ""}
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>

                    <Col xs={12} className="mt-4">
                        <button
                            className={"btn btn-clear"}
                            onClick={() => setShowFormulaModal(true)}
                        >
                            <u>Check formula note</u>
                        </button>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Applicant;
