import React from 'react';
import { Table, Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const ThirdSample = () => {
  const data = [
    {
      classification: 'Participation',
      date: '27-APR-2015',
      activity: 'National Physical Fitness Award',
      points: 1,
      status: 'Endorsed',
    },
    {
      classification: 'Achievement',
      date: '27-APR-2015',
      activity: 'Silver Award, National Physical Fitness Award',
      points: 4,
      status: 'Endorsed',
    },
  ];

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text('NANYANG POLYTECHNIC', 20, 10);
    doc.text('STUDENT AFFAIRS OFFICE', 20, 20);
    doc.text('STUDENT CCA REPORT', 20, 30);

    doc.autoTable({
      head: [['Classification', 'Date Of Involvement', 'Co-Curricular Activities (CCA)', 'CCA Points', 'Endorsement Status']],
      body: data.map(item => [item.classification, item.date, item.activity, item.points, item.status]),
    });

    doc.save('report.pdf');
  };

  const generateExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');
    XLSX.writeFile(wb, 'report.xlsx');
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center">Student CCA Report</h1>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Classification</th>
            <th>Date Of Involvement</th>
            <th>Co-Curricular Activities (CCA)</th>
            <th>CCA Points</th>
            <th>Endorsement Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.classification}</td>
              <td>{item.date}</td>
              <td>{item.activity}</td>
              <td>{item.points}</td>
              <td>{item.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-center mt-4">
        <Button onClick={generatePDF} className="mr-2">Download PDF</Button>
        <Button onClick={generateExcel}>Download Excel</Button>
      </div>
    </div>
  );
};

export default ThirdSample;
