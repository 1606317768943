import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {Button, Col, Form, Row, Tab, Table, Tabs,} from "@govtechsg/sgds-react";
import Select from "react-select";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {Accordion} from "@govtechsg/sgds-react/Accordion";
import FormulaModal from "@/components/FormulaModal";
import {getCodeTypeSasList, getStudentDetail, getStudentList,} from "@/apis/sims/api";
import {getListAcadYears, getListExercise,} from "@/apis/preparation/app-timeline/api";
import moment from "moment";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {toast} from "react-toastify";

function EnquiryStudentParticulars() {
    const [isOpen, setIsOpen] = useState(true);
    const contentRef = useRef(null);

    const [academicYear, setAcademicYear] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [showFormulaModal, setShowFormulaModal] = useState(false);

    const [listAcadYears, setListAcadYears] = useState([]);
    const [listExercise, setListExercise] = useState([]);
    const [options, setOptions] = useState([]);

    const [selectedAdmNo, setSelectedAdmNo] = useState(null);
    const [selectedListExercise, setSelectedListExercise] = useState(null);

    const [detail, setDetail] = useState(null);

    const [applicationDetail, setApplicationDetail] = useState(null);

    const [parentDetail, setParentDetail] = useState(null);

    const refTimeoutSearch = useRef(null);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const handleRetrieveData = async (e) => {
        e.preventDefault();

        const admNo = selectedAdmNo?.value ?? "";

        const exercise = selectedListExercise?.value ?? "";
        const selectedAcademicYear = academicYear?.value ?? "";

        const awardOptions = getCodeTypeSasList("AWARDTYPCODE");

        getStudentDetail(admNo, selectedAcademicYear).then((response) => {
            const studentData = response?.data?.data?.[0];
            setDetail(studentData);
        });
    };

    const getListOfAcadYears = () => {
        getListAcadYears().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let listYears = [];
                resp?.data?.data?.map((item) => {
                    listYears.push({value: item?.acad_yr, label: item?.acad_yr});
                });
                setListAcadYears(listYears);
            }
        });
    };

    const getListOfExercise = (year) => {
        getListExercise(year).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let listContent = [];
                resp?.data?.data?.map((item) => {
                    listContent.push({value: item?.exercise, label: item?.exercise});
                });
                setListExercise(listContent);
            }
        });
    };

    const handleGetListAdminNo = async (search = "") => {
        try {
            setSelectedAdmNo(null);
            setOptions([]);

            const params = {
                student_id: search,
                studstatus: "",
                transfer_tag: "N",
                page: 1,
                limit: 20,
                search: "",
                sort: "id_no",
            };

            const response = await getStudentList(params);
            if (response?.data?.data?.length > 0) {
                setOptions(
                    response?.data?.data?.map((item) => ({
                        label: item?.admNo,
                        value: item?.admNo,
                    }))
                );
            } else {
                const params = {
                    student_id: "",
                    studstatus: "",
                    transfer_tag: "N",
                    page: 1,
                    limit: 20,
                    search: search,
                    sort: "id_no",
                };
                const response = await getStudentList(params);
                setOptions(
                    response?.data?.data?.map((item) => ({
                        label: item?.admNo,
                        value: item?.admNo,
                    }))
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleSearch = (searchFunction = null, value = "") => {
        clearTimeout(refTimeoutSearch.current);

        if (value) {
            refTimeoutSearch.current = setTimeout(() => {
                searchFunction(value);
            }, 500);
        }
    };

    const getAdmOptions = () => {
        getStudentList().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let listOption = [];
                resp?.data?.data?.map((item) => {
                    listOption.push({value: item?.admNo, label: item?.admNo});
                });
                setOptions(listOption);
            }
        });
    };

    const [isExpandAll, setIsExpandAll] = useState(false);

    const [activeKeys, setActiveKeys] = useState([]);

    const accordions = [
        "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
        "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
        "21", "22", "23"
    ]

    const handleToggleAll = () => {
        if (isExpandAll) {
            setActiveKeys([]);
        } else {
            setActiveKeys(accordions.map(accordion => accordion));
        }
        setIsExpandAll(!isExpandAll);
    }

    useEffect(() => {
        getAdmOptions();
        getListOfAcadYears();
    }, []);

    return (
        <Layout currentNav={"utilities"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Utilities</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Enquire Student Personal Particulars
                </Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Enquire Student Personal Particulars</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>

                    <Form
                        ref={contentRef}
                        className={` d-flex flex-column gap-3 collapse-animation`}
                        style={{
                            overflow: isOpen ? "unset" : "hidden",
                            maxHeight: isOpen
                                ? `${
                                    contentRef?.current?.scrollHeight == null
                                        ? "100%"
                                        : contentRef?.current?.scrollHeight
                                }px`
                                : "0px",
                        }}
                        onSubmit={handleRetrieveData}
                    >
                        <Row className={`mt-3`}>

                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Adm No. or ID No.</Form.Label>
                                    <Select
                                        options={options}
                                        value={selectedAdmNo}
                                        onChange={(option) => setSelectedAdmNo(option)}
                                        onInputChange={(value) =>
                                            handleSearch(handleGetListAdminNo, value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Button type="submit" className="btn btn-primary">
                                    Retrieve
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    <button
                        onClick={toggleCollapse}
                        className="btn-clear text-blue ps-0 mt-3"
                    >
                        {isOpen ? "Hide " : "Expand "}{" "}
                        {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                    </button>
                </div>
                <div>
                    <h3>{detail?.name}</h3>
                </div>
                <div>
                    <Tabs
                        defaultActiveKey="personal_details"
                        id="uncontrolled-tab-example2"
                        variant="tabs-basic-toggle"
                        className="nav-fill"
                    >
                        <Tab eventKey="personal_details" title="Personal Details">
                            <div>
                                <div className="d-flex justify-content-end my-3">
                                    <button
                                        className="bg-white border-1 px-3 rounded-2 "
                                        onClick={handleToggleAll}
                                    >
                                        {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                        {" "}
                                        {!isExpandAll ? "Expand All" : "Collapse All"}
                                    </button>
                                </div>

                                <Accordion key={"0"}
                                           activeKey={activeKeys}
                                           className="">
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Personal Information
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row style={{rowGap: "1rem"}}>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>ID No.</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.idNo}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.name}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Date of birth</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={moment(detail?.birthDate).format(
                                                                "DD/MM/YYYY"
                                                            )}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Age</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.age}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Place of Birth</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.birthPlaceDesc}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Sex</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.sex == "F" ? "Female" : "Male"}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Race</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.raceDesc}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Race 2</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.race2Desc}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Religion</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.religionDesc}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Citizenship</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.citizenDesc}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>PR</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.pr}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion key={"1"}
                                           activeKey={activeKeys}>
                                    <Accordion.Item eventKey="1" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Term Address
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row style={{rowGap: "1rem"}}>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Block/House No. </Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.termAddr1}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Unit No</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.termUnitno}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Street Name</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.termAddr2}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Building</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.termAddr3}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.termCity}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>State/Province</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={
                                                                detail?.termState + " / " + detail?.termProvince
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Postal Code</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.termPostCode}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Country</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.termCountry}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion key={"2"}
                                           activeKey={activeKeys}>
                                    <Accordion.Item eventKey="2" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Contacts
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row style={{rowGap: "1rem"}}>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Telephone No. 1</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.termTel}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Telephone No. 2</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.termTel2}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Office Telephone No.</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.officeTel}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Handphone </Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.handphone}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>

                            <div className="mt-4">
                                <Accordion key={"3"}
                                           activeKey={activeKeys}>
                                    <Accordion.Item eventKey="3" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Emails
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row style={{rowGap: "1rem"}}>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Personal E-mail</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.personalEmail}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Office E-mail</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.studEmail}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>

                            <div className="mt-4">
                                <Accordion key={"4"}
                                           activeKey={activeKeys}>
                                    <Accordion.Item eventKey="4" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Marital Details
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row style={{rowGap: "1rem"}}>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Marital Status</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={
                                                                detail?.maritalStatus +
                                                                " - " +
                                                                detail?.maritalStatusDesc
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        <Form.Label>Marriage Date</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={
                                                                detail?.marriageDate
                                                                    ? moment(detail?.marriageDate).format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : "-"
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={3}>
                                                    <Form.Group>
                                                        <Form.Label>Spouse ID</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.spouseIdNo}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={9}>
                                                    <Form.Group>
                                                        <Form.Label>Spouse Name</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.spouseName}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Tab>
                        <Tab eventKey="course_details" title="Course Details">
                            <div>
                                <div className="d-flex justify-content-end my-3">
                                    <button
                                        className="bg-white border-1 px-3 rounded-2 "
                                        onClick={handleToggleAll}
                                    >
                                        {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                        {" "}
                                        {!isExpandAll ? "Expand All" : "Collapse All"}
                                    </button>
                                </div>
                                <Accordion key={"5"}
                                           activeKey={activeKeys}>
                                    <Accordion.Item eventKey="5" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Academic Progress & Support
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row style={{rowGap: "1rem"}}>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Personal Tutor Group</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList?.[0]?.tutgrp}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Progress Course Code</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList[0]?.courseCode}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>

                            <div className="mt-4">
                                <Accordion key={"6"}
                                           activeKey={activeKeys}>
                                    <Accordion.Item eventKey="6" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Study Status
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row style={{rowGap: "1rem"}}>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Start in (Year/Semester)</Form.Label>
                                                        <div className="d-flex gap-2">
                                                            <Form.Control
                                                                type={`text`}
                                                                className={`w-50`}
                                                                disabled={isDisabled}
                                                                value={detail?.courseList[0]?.startAcadYr}
                                                            ></Form.Control>
                                                            <Form.Control
                                                                type={`text`}
                                                                className={`w-50`}
                                                                disabled={isDisabled}
                                                                value={detail?.courseList[0]?.startSemester}
                                                            ></Form.Control>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Stage of Study</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList[0]?.studyStage}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Status</Form.Label>
                                                        <div className="d-flex gap-2">
                                                            <Form.Control
                                                                type={`text`}
                                                                className={`w-25`}
                                                                disabled={isDisabled}
                                                                value={detail?.courseList[0]?.studStatus}
                                                            ></Form.Control>
                                                            <Form.Control
                                                                type={`text`}
                                                                className={`w-75`}
                                                                disabled={isDisabled}
                                                                value={detail?.courseList[0]?.studStatusDesc}
                                                            ></Form.Control>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>

                            <div className="mt-4">
                                <Accordion key={"7"}
                                           activeKey={activeKeys}>
                                    <Accordion.Item eventKey="7" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Sponsorship
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row style={{rowGap: "1rem"}}>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Sponsor by</Form.Label>
                                                        <div className="d-flex gap-2">
                                                            <Form.Control
                                                                type={`text`}
                                                                className={`w-25`}
                                                                disabled={isDisabled}
                                                                value={detail?.courseList?.[0]?.sponsorNyp}
                                                            ></Form.Control>
                                                            <Form.Control
                                                                type={`text`}
                                                                className={`w-75`}
                                                                disabled={isDisabled}
                                                                value={detail?.courseList[0]?.sponsorDesc}
                                                            ></Form.Control>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Co- Sponsor</Form.Label>
                                                        <div className="d-flex gap-2">
                                                            <Form.Control
                                                                type={`text`}
                                                                className={`w-25`}
                                                                disabled={isDisabled}
                                                                value={detail?.courseList[0]?.coSponsor}
                                                            ></Form.Control>
                                                            <Form.Control
                                                                type={`text`}
                                                                className={`w-75`}
                                                                disabled={isDisabled}
                                                                value={detail?.courseList[0]?.coSponsorDesc}
                                                            ></Form.Control>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Sponsor Amount </Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList[0]?.sponsorAmt}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Sponsor Allowance</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList[0]?.sponsorAllowance}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Compulsory</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList[0]?.sponsorFlagDesc}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Sponsor Terminated by</Form.Label>
                                                        <div className="d-flex gap-2">
                                                            <Form.Control
                                                                type={`text`}
                                                                className={`w-25`}
                                                                disabled={isDisabled}
                                                                value={
                                                                    detail?.courseList[0]?.sponsorTerminatedBy
                                                                }
                                                            ></Form.Control>
                                                            <Form.Control
                                                                type={`text`}
                                                                className={`w-75`}
                                                                disabled={isDisabled}
                                                                value={
                                                                    detail?.courseList[0]?.sponsorTerminatedByDesc
                                                                }
                                                            ></Form.Control>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Asian Scholar</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList[0]?.asianScholar}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Allied HS Scholar</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList[0]?.alliedHsScholar}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>University/Center</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList[0]?.univeristyCentre}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Received by NYP Date</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={
                                                                detail?.courseList?.[0]?.sponsAllowRcvByNypDate
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Sponsored Update Date</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={
                                                                detail?.courseList[0]?.sponsorUpdDate
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Tab>
                        <Tab eventKey="family_info" title="Family Info">
                            <div className="d-flex justify-content-end my-3">
                                <button
                                    className="bg-white border-1 px-3 rounded-2 "
                                    onClick={handleToggleAll}
                                >
                                    {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                    {" "}
                                    {!isExpandAll ? "Expand All" : "Collapse All"}
                                </button>
                            </div>
                            {detail?.parentList?.map((parent, index) => (
                                <div className="mt-4">
                                    <Accordion key={`${8+index}`}
                                               activeKey={activeKeys}>
                                        <Accordion.Item eventKey={`${8+index}`} className="shadow border-0">
                                            <Accordion.Header className="header-text text-blue">
                                                <div className="d-flex gap-3">
                                                    {parent?.name} - {parent?.relationDesc}
                                                    {/*<span className={`text-success fw-normal`}> {" "}*/}
                                                    {/*    <i className={`bi bi-check-circle`}></i>*/}
                                                    {/*    Primary contact{" "}*/}
                                                    {/*</span>*/}
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Row style={{rowGap: "1rem"}}>
                                                    <h5 className={`mt-2 text-black-50`}>
                                                        Relation to Applicant
                                                    </h5>
                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Relation</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.relationDesc}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <h5 className={`mt-2 text-black-50`}>
                                                        Personal Information
                                                    </h5>
                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>ID No. </Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.idNo}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Name</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.name}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Citizen</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.citizenDesc}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>PR Status</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.prStatus}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Race</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.raceDesc}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <h5 className={`mt-2 text-black-50`}>
                                                        Contact Information
                                                    </h5>
                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Contact No. </Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.contactNo}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Handphone No.</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.handphone}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.email}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <h5 className={`mt-2 text-black-50`}>
                                                        Personal Information
                                                    </h5>
                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Qualifications</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.qualDesc}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Occupation</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.occupationDesc}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Designation</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.designation}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Organisation</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.organisation}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <h5 className={`mt-2 text-black-50`}>
                                                        Address Information
                                                    </h5>
                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>House Block</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.address1}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Unit No.</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.unitNo}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Street</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.address2}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Building</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.address3}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>City</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.city}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>State</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.province}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Country</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.country}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Postal Code</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={parent?.postcode}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <h5 className={`mt-2 text-black-50`}>Housing</h5>
                                                    <Col xs={12} className="">
                                                        <Form.Group>
                                                            <Form.Label>House Type</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={detail?.homeHouseTypeDesc}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <h5 className={`mt-2 text-black-50`}>
                                                        Family Income{" "}
                                                    </h5>
                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Family Amount (exact amount)
                                                            </Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={detail?.famIncomeExactAmt}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className="">
                                                        <Form.Group>
                                                            <Form.Label>Family Income (in range)</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={isDisabled}
                                                                value={detail?.famIncomeDesc}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={12}>
                                                        <Form.Check
                                                            disabled={true}
                                                            label={`Set as primary contact`}
                                                            id={"primary-contact"}
                                                        ></Form.Check>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            ))}
                        </Tab>
                        <Tab eventKey="financial_assistant" title="Financial Assistant">
                            <div className="mt-4">
                                <Table size={"sm"} responsive>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>#</Table.HeaderCell>
                                            <Table.HeaderCell>Award Type</Table.HeaderCell>
                                            <Table.HeaderCell>Award</Table.HeaderCell>
                                            <Table.HeaderCell>Year Awarded</Table.HeaderCell>
                                            <Table.HeaderCell>Amount Awarded</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {detail?.studFinAssistList.map((fin, index) => (
                                            <Table.Row key={index}>
                                                <Table.DataCell>{index + 1}</Table.DataCell>
                                                <Table.DataCell>{fin?.awardTypeDesc}</Table.DataCell>
                                                <Table.DataCell>{fin?.awardDesc}</Table.DataCell>
                                                <Table.DataCell>{fin?.yrAwarded}</Table.DataCell>
                                                <Table.DataCell>{fin?.amtAwarded}</Table.DataCell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        </Tab>
                        <Tab eventKey="tuition_grant" title="Tuition Grant">
                            <div className="d-flex justify-content-end my-3">
                                <button
                                    className="bg-white border-1 px-3 rounded-2 "
                                    onClick={handleToggleAll}
                                >
                                    {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                    {" "}
                                    {!isExpandAll ? "Expand All" : "Collapse All"}
                                </button>
                            </div>
                            <div className="mt-4">
                                <Accordion key={"20"}
                                           activeKey={activeKeys}>
                                    <Accordion.Item eventKey="20" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Personal Information
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row style={{rowGap: "1rem"}}>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Student Status</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList?.[0]?.studStatusDesc}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Status Effective Date</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList?.[0]?.tgEffectiveDate}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Date of Admission</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList?.[0]?.tgAdmDate}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Eligible for Tuition Grant</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.courseList?.[0]?.tgTutGrant}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion key={"21"}
                                           activeKey={activeKeys}>
                                    <Accordion.Item eventKey="21" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            <div className="d-flex gap-3">Tuition Grant Taken</div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row style={{rowGap: "1rem"}}>
                                                <h5 className={`mt-2 text-black-50`}>
                                                    Current Admission
                                                </h5>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Tuition Grant Taken </Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.tgSemNypCurr}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <h5 className={`mt-2 text-black-50`}>
                                                    Previous Admission and Other Polytechnic
                                                </h5>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Tuition Grant Taken</Form.Label>
                                                        <Form.Text className="text-muted">
                                                            Previous Admission at NYP
                                                        </Form.Text>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.tgSemNypPrev}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Tuition Grant Taken</Form.Label>
                                                        <Form.Text className="text-muted">
                                                            Other Polytechnics
                                                        </Form.Text>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.tgSemOtherPoly}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Date Generated</Form.Label>
                                                        <Form.Text className="text-muted">
                                                            for previous NYP & Other Polytechnics
                                                        </Form.Text>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.tgSemUpdDte}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <h5 className={`mt-2 text-black-50`}>
                                                    Updates & Expiry
                                                </h5>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Last Update Date (by Finance)
                                                        </Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.tgSemNypCurrDate}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Updated by</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.tgSemUpdId}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Tuition Grant Expired Date</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={detail?.tgSemNypExpDate}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </sgds-content-body>
            <FormulaModal
                show={showFormulaModal}
                handleClose={() => setShowFormulaModal(!showFormulaModal)}
            />
        </Layout>
    );
}

export default EnquiryStudentParticulars;
