import {Accordion} from "@govtechsg/sgds-react/Accordion";
import {Col, Form, Row, TableBody, TableDataCell, TableHeader, TableHeaderCell, TableRow,} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import {Table} from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";

const genderOptions = [
    {value: "M", label: "Male"},
    {value: "F", label: "Female"},
];

export default function MTRequest({
                                      isDisabled,
                                      applicationDetail,
                                      listMTCRequestResult,
                                      listMTCDetails,
                                      listMTIncomes,
                                      listMTConsents,
                                      listMTCases,
                                      listMTCaseNotes,
                                      mtRequestStatusOptions,
                                      specialCaseOptions,
                                      careArrangementOptions,
                                      details,
                                      specialMeansStatusOptions,
                                      maritalStatusOptions,
                                      residentialOptions,
                                      consentTypeOptions,
                                      consentScopeOptions,
                                      consentStatusOptions,
                                      legalCapacityOptions,
                                      relationshipOptions,
                                      occupationOptions,
                                      listMTCDetailsM,
                                      listMTCDetailsA,
                                      activeKeys,
                                      handleToggleAccordion
                                  }) {
    const [totalCCCIncome, setCCCIncome] = useState(0);
    const [totalGrossIncome, setTotalGrossIncome] = useState(0);

    useEffect(() => {
        let total = 0;
        details?.map((content) => {
            if (content?.mtc_tag != "D") {
                total += Number(content?.avg_mthly_income);
            }
        });
        setTotalGrossIncome(total);
        setCCCIncome(total / (details?.length > 0 ? details?.length : 1));
    }, [details]);

    return (
        <>
            <div className="">
                <Accordion key="17" activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="17" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("17")}>
                            Application
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Request status</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.status_tag ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Mean Test Date</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.mt_date ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Mean Test Computed On</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.mt_date ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                Changes after Mean Test Computation
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.changesaftermtcomputation ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Income Point Value PCI </Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.incomepointvalue_pci ?? ""}
                                                // value={totalCCCIncome}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Income Point Value Gross Income </Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.incomepointvalue_gi ?? ""}
                                                // value={totalGrossIncome}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Escalated to</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.escalated_to ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Escalated from</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.escalated_from ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>TLC Indicator</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.tlc_indicator ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Special Case </Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.special_case ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Reviewed by</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.reviewed_by ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Application ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.application_id ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Request status</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.status_tag ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Care Arrangement </Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.care_arrangement ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Contact Number </Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.contact_number ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsM?.[0]?.mt_request_results?.[0]?.email_address ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="">
                <Accordion key="18" activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="18" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("18")}>
                            Family Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>

                                {
                                    listMTCDetailsM?.map((detail, index) => {
                                        return (
                                            <Row>
                                                <Col xs={12} className={`fw-bold`}>
                                                    #{index + 1} Member{" "}
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.hh_name ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(index, "hh_name", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            NRIC/Fin/Passport No. (if foreigner)
                                                            <span className={`text-danger`}> *</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled={true}
                                                            value={detail?.hh_uin ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(index, "hh_uin", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group className={`d-flex flex-column`}>
                                                        <Form.Label>Date of Birth</Form.Label>
                                                        <DatePicker
                                                            selected={detail?.dob == undefined ? null : detail?.dob}
                                                            className={`form-control`}
                                                            onChange={(date) => {
                                                                if (date == null) {
                                                                    handleChange(index, "dob", "");
                                                                } else {
                                                                    handleChange(
                                                                        index,
                                                                        "dob",
                                                                        moment(date).format("YYYY-MM-DD")
                                                                    );
                                                                }
                                                            }}
                                                            dateFormat="dd/MM/yyyy"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            disabled={true}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Age</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled={true}
                                                            value={detail?.dob && Math.floor((new Date() - new Date(detail?.dob)) / (365.25 * 24 * 60 * 60 * 1000)) }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Gender</Form.Label>
                                                        <Select
                                                            options={genderOptions}
                                                            isDisabled={true}
                                                            value={{
                                                                value: detail?.gender,
                                                                label:
                                                                    detail?.gender == "M" ? "Male" : "Female",
                                                            }}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "gender",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Relationship Status</Form.Label>
                                                        <Select
                                                            options={relationshipOptions}
                                                            isDisabled={true}
                                                            value={relationshipOptions.find(option => option.value === detail?.relationship)}
                                                            // value={{
                                                            //     value: detail?.RELATIONSHIP,
                                                            //     label: detail?.RELATIONSHIP,
                                                            // }}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "relationship",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Residental Status</Form.Label>
                                                        <Select
                                                            options={residentialOptions}
                                                            value={residentialOptions.find(option => option.value === detail?.res_status)}
                                                            isDisabled={true}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "res_status",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Martial Status</Form.Label>
                                                        <Select
                                                            options={maritalStatusOptions}
                                                            value={maritalStatusOptions.find(option => option.value === detail?.marital_status)}
                                                            isDisabled={true}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "marital_status",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className={`fw-bold my-3 text-blue`}>
                                                    Income Details
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Occupation</Form.Label>
                                                        <Select
                                                            options={occupationOptions}
                                                            value={occupationOptions.find(option => option.value === detail?.occupation)}
                                                            isDisabled={true}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "occupation",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Avg Monthly Income</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.avg_mthly_income ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "avg_mthly_income",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Other income</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.other_income ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "other_income",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Remarks</Form.Label>
                                                        <Form.Text className="text-muted">
                                                            Reason if occupation status is unemployed
                                                        </Form.Text>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.remarks ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(index, "remarks", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Special Means Status</Form.Label>
                                                        <Select
                                                            isDisabled={true}
                                                            options={specialMeansStatusOptions}
                                                            value={specialMeansStatusOptions.find(option => option.value === detail?.special_means_status)}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "special_means_status",
                                                                    e.value
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className={`fw-bold my-3 text-blue`}>
                                                    Consent Details
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Reusable Consent</Form.Label>
                                                        <Form.Text className="text-muted">
                                                            If no, please specify Consent NRIC and Name
                                                        </Form.Text>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.reusable_consentscope ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "reusable_consentscope",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Reusable Consent ID</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.reusable_consentid ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "reusable_consentid",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Reusable Consent Scope</Form.Label>
                                                        <Select
                                                            isDisabled={true}
                                                            options={consentScopeOptions}
                                                            value={consentScopeOptions.find(option => option.value === applicationDetail?.reusable_consentscope)}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "reusable_consentscope",
                                                                    e.value
                                                                )
                                                            }
                                                        />
                                                        {/*<Form.Control*/}
                                                        {/*    type={`text`}*/}
                                                        {/*    disabled={true}*/}
                                                        {/*    value={detail?.REUSABLE_CONSENTSCOPE}*/}
                                                        {/*    onChange={(e) =>*/}
                                                        {/*        handleChange(*/}
                                                        {/*            index,*/}
                                                        {/*            "REUSABLE_CONSENTSCOPE",*/}
                                                        {/*            e.target.value*/}
                                                        {/*        )*/}
                                                        {/*    }*/}
                                                        {/*></Form.Control>*/}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Is No Valid Auth</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.isnovalidauth ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "isnovalidauth",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <h5 className={`mt-5`}>Consent Detail</h5>


                                                {detail?.mt_consent_details?.map((condetail, detailIndex) => (
                                                    <Row key={detailIndex}>
                                                        <Col xs={12}>
                                                            Consent Detail #{detailIndex + 1}
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Type</Form.Label>
                                                                <Select
                                                                    isDisabled={true}
                                                                    options={consentTypeOptions}
                                                                    value={consentTypeOptions.find(option => option.value === condetail?.consent_type)}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.consent_type",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={detail?.MT_CONSENT_DETAILS?.CONSENT_TYPE}*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.CONSENT_TYPE",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Scope</Form.Label>
                                                                <Select
                                                                    isDisabled={true}
                                                                    options={consentScopeOptions}
                                                                    value={consentScopeOptions.find(option => option.value === condetail?.consent_scope)}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.consent_scope",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={detail?.MT_CONSENT_DETAILS?.CONSENT_SCOPE}*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.CONSENT_SCOPE",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Legal Capacity</Form.Label>
                                                                <Select
                                                                    isDisabled={true}
                                                                    options={legalCapacityOptions}
                                                                    value={legalCapacityOptions.find(option => option.value === condetail?.legal_capacity)}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.legal_capacity",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={*/}
                                                                {/*        detail?.MT_CONSENT_DETAILS?.LEGAL_CAPACITY*/}
                                                                {/*    }*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.LEGAL_CAPACITY",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Manual Consent</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={true}
                                                                    value={
                                                                        condetail?.nyp_manual_consent ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_manual_consent",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent NRIC</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={true}
                                                                    value={
                                                                        condetail?.nyp_consent_id ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_consent_id",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Name</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={true}
                                                                    value={
                                                                        condetail?.nyp_consent_name ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_consent_name",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Email</Form.Label>
                                                                <Form.Control
                                                                    type={`email`}
                                                                    disabled={true}
                                                                    value={
                                                                        condetail?.nyp_consent_email ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_consent_email",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Status</Form.Label>
                                                                <Select
                                                                    isDisabled={true}
                                                                    options={consentStatusOptions}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_consent_status",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={*/}
                                                                {/*        detail?.MT_CONSENT_DETAILS?.NYP_CONSENT_STATUS*/}
                                                                {/*    }*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.NYP_CONSENT_STATUS",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Date</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={true}
                                                                    value={
                                                                        condetail?.nyp_consent_date ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_consent_date",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                ))}

                                                <Col xs={12}>
                                                    <Form.Group>
                                                        <Form.Label>Remarks</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.remarks ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(index, "remarks", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                        )
                                    })
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>


            <div className="">
                <Accordion key="19" activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="19" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("19")}>
                            Mean Test Results
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>MT Request ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsA?.[0]?.mt_request_results?.[0]?.request_id ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Mean Test ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsA?.[0]?.mt_request_results?.[0]?.means_test_id ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Status Tag</Form.Label>

                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsA?.[0]?.mt_request_results?.[0]?.status_tag ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Change Type</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={isDisabled}
                                                value={listMTCDetailsA?.[0]?.mt_request_results?.[0]?.change_type ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="">
                <Accordion key="20" activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="20" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("20")}>
                            Case Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Label>Case Detail</Form.Label>
                                    </Col>

                                    <Col xs={12}>
                                        <Table responsive>
                                            <TableHeader>
                                                <TableHeaderCell>#</TableHeaderCell>
                                                <TableHeaderCell>Case ID</TableHeaderCell>
                                                <TableHeaderCell>Case Type</TableHeaderCell>
                                                <TableHeaderCell>Case Subject</TableHeaderCell>
                                                <TableHeaderCell>Case Status</TableHeaderCell>
                                                <TableHeaderCell>Last Update</TableHeaderCell>
                                            </TableHeader>

                                            <TableBody>
                                                {listMTCDetailsA?.[0]?.mt_cases?.map((ecase, index) => (
                                                    <TableRow key={index}>
                                                        <TableDataCell>{ecase?.index + 1}</TableDataCell>
                                                        <TableDataCell>{ecase?.case_id}</TableDataCell>
                                                        <TableDataCell>{ecase?.case_type}</TableDataCell>
                                                        <TableDataCell>{ecase?.case_subject}</TableDataCell>
                                                        <TableDataCell>{ecase?.case_status}</TableDataCell>
                                                        <TableDataCell>{ecase?.updated_at}</TableDataCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Label>Case Notes</Form.Label>
                                    </Col>

                                    <Col xs={12}>
                                        <Table responsive>
                                            <TableHeader>
                                                <TableHeaderCell>#</TableHeaderCell>
                                                <TableHeaderCell>Case ID</TableHeaderCell>
                                                <TableHeaderCell>Case Notes</TableHeaderCell>
                                                {/*<TableHeaderCell>*/}
                                                {/*    Case Status*/}
                                                {/*</TableHeaderCell>*/}
                                                <TableHeaderCell>Last Update</TableHeaderCell>
                                            </TableHeader>

                                            <TableBody>
                                                <TableRow>
                                                    {listMTCDetailsA?.[0]?.mt_case_notes?.map((ecase, index) => (
                                                        <TableRow key={index}>
                                                            <TableDataCell>{ecase?.index + 1}</TableDataCell>
                                                            <TableDataCell>{ecase?.case_id}</TableDataCell>
                                                            <TableDataCell>{ecase?.case_notes}</TableDataCell>
                                                            <TableDataCell>{ecase?.updated_at}</TableDataCell>
                                                        </TableRow>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}
