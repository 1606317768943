import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {Col, Container, Row, Table} from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {utils, writeFile} from "xlsx";
import {TableBody, TableDataCell, TableHeader, TableHeaderCell, TableRow} from "@govtechsg/sgds-react";

const familyDetails = {
    father: {
        status: "Living in the same household",
        name: "F of 134445H",
        occupation: "SELF-EMPLOYED",
    },
    mother: {
        status: "Living in the same household",
        name: "M of 134445H",
        occupation: "SELF-EMPLOYED",
    },
    siblings: [
        {id: "1rhvsrgivQ", name: "of 134445H", age: 17, occupation: "STUDENT"},
        {id: "2buztnochR", name: "of 134445H", age: 15, occupation: "STUDENT"},
        {id: "2zdkasrniN", name: "of 134445H", age: 74, occupation: "UNEMPLOYED"},
        {id: "3zwytebifC", name: "of 134445H", age: 73, occupation: "UNEMPLOYED"},
        {id: "4thrcologF", name: "of 134445H", age: 42, occupation: "UNEMPLOYED"},
        {
            id: "4ygdffnlkE",
            name: "NP of 134445H",
            age: 20,
            occupation: "EMPLOYED",
        },
        {id: "7lmkakajIK", name: "of 134445H", age: 13, occupation: "STUDENT"},
        {id: "8uptptjiIS", name: "of 134445H", age: 46, occupation: "EMPLOYED"},
    ],
    totalMembers: 11,
    monthlyIncome: 0,
    mpci: 818.18,
};

const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Financial Assistance Application - Family Details", 20, 10);
    doc.autoTable({html: "#family-details-table"});
    doc.save("family-details.pdf");
};

const generateExcel = () => {
    const wb = utils.book_new();
    const ws = utils.table_to_sheet(
        document.getElementById("family-details-table")
    );
    utils.book_append_sheet(wb, ws, "Family Details");
    writeFile(wb, "family-details.xlsx");
};

function Sample() {
    return (
        <Container>
            <Row>
                <Col xs={12} className={`text-center`}>
                    <h3>Financial Assistance Application - Family Details</h3>
                </Col>

                <Col xs={12}>
                    <Row>
                        <Col xs={6}>
                            <Row>
                                <Col xs={4}>
                                    <b>Acade Yr</b> 2018
                                </Col>
                                <Col xs={4}>
                                    <b>Exercise</b> 9
                                </Col>
                                <Col xs={4}>
                                    <b>Adm No</b> 134445H
                                </Col>
                                <Col xs={12}>
                                    <b>Name</b> Name of 134445H
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={6}>
                            <Row>
                                <Col xs={5} className={`text-end`}>
                                    <b>
                                        NRIC (local & S'pore PR)/ Passport No. (foreigner)
                                    </b>
                                </Col>
                                <Col xs={7}>
                                    IDN 134445H
                                </Col>

                                <Col xs={5} className={`text-end`}>
                                    <b>
                                        Ack No
                                    </b>
                                </Col>
                                <Col xs={7}>
                                    12930128301283-12318237
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12}>
                    <Row>
                        <Col xs={6}>
                            <Row>
                                <Col xs={4}>
                                    <h4 className={`text-end text-red-700`}>Father</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={4} className={`text-end`}>
                                    <b>Status</b>
                                </Col>
                                <Col xs={8}>
                                    Living in the same household
                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Name</b>
                                </Col>
                                <Col xs={8}>
                                    F of 123123
                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Occupation</b>
                                </Col>
                                <Col xs={8}>
                                    SELF-EMPLOYED
                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Child Support</b>
                                </Col>
                                <Col xs={8}>

                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Remarks</b>
                                </Col>
                                <Col xs={8}>

                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>NRIC (local & S'pore PR)/ Passport No. (foreigner)</b>
                                </Col>
                                <Col xs={8}>
                                    alasdlk1j23l1n23
                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Age</b>
                                </Col>
                                <Col xs={8}>
                                    55
                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Gross Monthly Income</b>
                                </Col>
                                <Col xs={8}>

                                </Col>
                            </Row>
                        </Col>

                        <Col xs={6}>
                            <Row>
                                <Col xs={4}>
                                    <h4 className={`text-end text-red-700`}>Mother</h4>
                                </Col>
                            </Row>


                            <Row>
                                <Col xs={4} className={`text-end`}>
                                    <b>Status</b>
                                </Col>
                                <Col xs={8}>
                                    Living in the same household
                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Name</b>
                                </Col>
                                <Col xs={8}>
                                    M of 123123
                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Occupation</b>
                                </Col>
                                <Col xs={8}>
                                    SELF-EMPLOYED
                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Child Support</b>
                                </Col>
                                <Col xs={8}>

                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Remarks</b>
                                </Col>
                                <Col xs={8}>

                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>NRIC (local & S'pore PR)/ Passport No. (foreigner)</b>
                                </Col>
                                <Col xs={8}>
                                    oiuewo123mn45
                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Age</b>
                                </Col>
                                <Col xs={8}>
                                    49
                                </Col>

                                <Col xs={4} className={`text-end`}>
                                    <b>Gross Monthly Income</b>
                                </Col>
                                <Col xs={8}>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} className={`border border-1 border-dark my-3`}>
                    <h4 className={`text-red-700 mb-3`}>Siblings/Relatives</h4>
                    <Table borderless={true} size={"sm"}>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>
                                    ID No
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    Name
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    Age
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    Occupation
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    Gross Monthly Income
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    Monthly Conrtibution
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    Remarks
                                </TableHeaderCell>
                            </TableRow>
                        </TableHeader>

                        <TableBody>
                            {
                                familyDetails.siblings.map((sibling, index) => (
                                    <TableRow>
                                        <TableDataCell>{sibling.id}</TableDataCell>
                                        <TableDataCell>{sibling.name}</TableDataCell>
                                        <TableDataCell>{sibling.age}</TableDataCell>
                                        <TableDataCell>{sibling.occupation}</TableDataCell>
                                        <TableDataCell></TableDataCell>
                                        <TableDataCell></TableDataCell>
                                        <TableDataCell></TableDataCell>
                                    </TableRow>
                                ))
                            }

                        </TableBody>
                    </Table>

                </Col>

                <Col xs={12} className={`mb-3`}>
                    <Row>
                        <Col xs={11} className={`text-end`}>
                            <b>No. of family members living in the same household</b>
                        </Col>

                        <Col xs={1}>
                            11
                        </Col>

                        <Col xs={11} className={`text-end`}>
                            <b>Citizen's consulative Commitee (CCC) Income</b>
                        </Col>

                        <Col xs={1}>
                            0
                        </Col>

                        <Col xs={11} className={`text-end`}>
                            <b>Monthly Per Capital Income (MPCI)</b>
                        </Col>

                        <Col xs={1}>
                            818.18
                        </Col>
                    </Row>
                </Col>

                {/*<Table striped bordered hover id="family-details-table">*/}
                {/*    <thead>*/}
                {/*    <tr>*/}
                {/*        <th>Name</th>*/}
                {/*        <th>Age</th>*/}
                {/*        <th>Occupation</th>*/}
                {/*    </tr>*/}
                {/*    </thead>*/}
                {/*    <tbody>*/}
                {/*    <tr>*/}
                {/*        <td>{familyDetails.father.name}</td>*/}
                {/*        <td>55</td>*/}
                {/*        <td>{familyDetails.father.occupation}</td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td>{familyDetails.mother.name}</td>*/}
                {/*        <td>48</td>*/}
                {/*        <td>{familyDetails.mother.occupation}</td>*/}
                {/*    </tr>*/}
                {/*    {familyDetails.siblings.map((sibling, index) => (*/}
                {/*        <tr key={index}>*/}
                {/*            <td>{sibling.id}</td>*/}
                {/*            <td>{sibling.age}</td>*/}
                {/*            <td>{sibling.occupation}</td>*/}
                {/*        </tr>*/}
                {/*    ))}*/}
                {/*    </tbody>*/}
                {/*</Table>*/}
                {/*<Button variant="primary" onClick={generatePDF}>*/}
                {/*    Download PDF*/}
                {/*</Button>*/}
                {/*<Button variant="secondary" onClick={generateExcel} className="ml-2">*/}
                {/*    Download Excel*/}
                {/*</Button>*/}
            </Row>
        </Container>
    );
}

export default Sample;
