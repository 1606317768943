import {Accordion, Col, Form, FormCheck, Row} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {getCodeList} from "@/apis/preparation/setup-dialect-etc/api";

const PersonalData = ({
                          applicationDetail,
                          setApplicationDetail,
                          studentDetail,
                          isDisabled,
                          options,
                          handleClickEdit,
                          academicYear,
                          handleAcademicYearChange,
                          selectedListExercise,
                          selectedListAdmNo,
                          maritalStatus,
                          setMaritalStatus,
                          dialectGroup,
                          setDialectGroup,
                          housingType,
                          setHousingType,
                          handleChange,
                          activeKeys,
                          handleToggleAccordion,
                          specialCircumstanceOptions
                      }) => {
    const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
    const [dialectGroupOptions, setDialectGroupOptions] = useState([]);
    const [housingTypeOptions, setHousingTypeOptions] = useState([]);

    const getMaritalStatusOptions = () => {
        getCodeList("MS")
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.code,
                            label: `${item?.code} - ${item?.description}`,
                            code: item?.code,
                        });
                    });
                    setMaritalStatusOptions([{label: "", value: null}, ...listContent]);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const getDialectGroupOptions = () => {
        getCodeList("DG")
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.code,
                            label: `${item?.code} - ${item?.description}`,
                            code: item?.code,
                        });
                    });
                    setDialectGroupOptions(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const getHousingTypeOptions = () => {
        getCodeList("H")
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({value: item?.id, label: item?.code});
                    });
                    setHousingTypeOptions(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        setMaritalStatus({
            label: maritalStatusOptions.map((content) => {
                if (applicationDetail?.marital_status === content?.code) {
                    return content?.label;
                }
            }),
            value: applicationDetail?.marital_status,
        });
        setDialectGroup({
            label: dialectGroupOptions.map((content) => {
                if (applicationDetail?.dialect_grp === content?.code) {
                    return content?.label;
                }
            }),
            value: applicationDetail?.dialect_grp,
        });
        setHousingType({
            label: housingTypeOptions.map((content) => {
                if (applicationDetail?.homehousetype === content?.code) {
                    return content?.label;
                }
            }),
            value: applicationDetail?.homehousetype,
        });
    }, [
        applicationDetail?.marital_status,
        applicationDetail?.dialect_grp,
        applicationDetail?.homehousetype,
    ]);

    const onChangeMaritalStatus = (selectedOption) => {
        handleChange("marital_status", selectedOption.value)
        setMaritalStatus({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };
    const onChangeDialectGroup = (selectedOption) => {
        handleChange("dialect_grp", selectedOption.value)
        setDialectGroup({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };
    const onChangeHousingType = (selectedOption) => {
        setHousingType({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };

    useEffect(() => {
        getMaritalStatusOptions();
        getDialectGroupOptions();
        getHousingTypeOptions();
    }, []);

    return (
        <>
            <div className="">
                <Accordion key="0" activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("0")}>
                            Personal Data
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                            <Row>
                                    <Col xs={4} className="">
                                        <Form.Group>
                                            <Form.Label>Academic Year<span
                                                className={`text-danger`}> *</span></Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.acad_yr ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4} className="">
                                        <Form.Group>
                                            <Form.Label>Exercise<span className={`text-danger`}> *</span></Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.exercise ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4} className="">
                                        <Form.Group>
                                            <Form.Label>Adm No.<span className={`text-danger`}> *</span></Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.adm_no ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.name ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>
                                                NRIC/Fin/Passport No. (if foreigner)
                                            </Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.idNo ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Student Status</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={
                                                    studentDetail?.courseList?.length > 0
                                                        ? (`${studentDetail?.courseList[0]?.studStatus} (${studentDetail?.courseList[0]?.studStatusDesc})`)
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Course</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={
                                                    studentDetail?.courseList?.length > 0
                                                        ? studentDetail?.courseList[0]?.courseName
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Citizenship</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={`${studentDetail?.citizen ? `${studentDetail?.citizen} (${studentDetail?.citizenDesc})` : "" }`}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Residential Status</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Contact Number</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.termTel ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Housing Type</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={`${applicationDetail?.homehousetype ? `${applicationDetail?.homehousetype} (${applicationDetail?.homeHouseTypeDesc})` : "" }`}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Marital Status</Form.Label>
                                            <Select
                                                options={maritalStatusOptions}
                                                isDisabled={isDisabled}
                                                value={maritalStatus}
                                                onChange={onChangeMaritalStatus}
                                                isClearable
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Dialect Group</Form.Label>
                                            <Select
                                                options={dialectGroupOptions}
                                                isDisabled={isDisabled}
                                                value={dialectGroup}
                                                onChange={onChangeDialectGroup}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion key="1" activeKey={activeKeys}>
                    <Accordion.Item eventKey="1" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("1")}>
                            Residential Address
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Block/House No. & Street Name</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={`${studentDetail?.termAddr1 ?? ""} ${studentDetail?.termAddr2 ?? ""} ${studentDetail?.termAddr3 ?? ""}`}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Postal Code</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.termPostCode ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Floor Unit No</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.termUnitno ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion key="2" activeKey={activeKeys} >
                    <Accordion.Item eventKey="2" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("2")}>
                            Others
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12} className="">
                                        <FormCheck
                                            id="onComcare"
                                            label="On Comcare"
                                            name="onComcare "
                                            type="checkbox"
                                            checked={applicationDetail?.comcare === "Y"}
                                            onChange={(e) =>
                                                handleChange("comcare", e.target.checked ? "Y" : null)
                                            }
                                            disabled={isDisabled}
                                        />
                                    </Col>
                                    <Col xs={12} className="">
                                        <Form.Group>
                                            <Form.Label>Special Circumstances</Form.Label>
                                            {specialCircumstanceOptions.length > 0 ? specialCircumstanceOptions.map((content, index) => {
                                                const isChecked = applicationDetail?.special_circumstances?.split('|')?.includes(content.value);

                                                return (
                                                    <Form.Check
                                                        key={`special_circumstances_${index}`} // Add a unique key for each checkbox
                                                        id={`special_circumstances_${index}`}
                                                        label={content.label}
                                                        name="special_circumstances"
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={(e) => {
                                                            const currentCircumstances = applicationDetail?.special_circumstances?.split('|')?.filter(item => item) || [];
                                                            console.log(currentCircumstances)
                                                            let updatedCircumstances;

                                                            if (e.target.checked) {
                                                                // If checked, add the new value
                                                                updatedCircumstances = [...currentCircumstances, content.value];
                                                            } else {
                                                                // If unchecked, remove the value
                                                                updatedCircumstances = currentCircumstances.filter(item => item !== content.value);
                                                            }
                                                            
                                                            // Create the joined string, ensuring no leading '|'
                                                            const joinedCircumstances = updatedCircumstances.length > 0 
                                                                ? updatedCircumstances.join('|') 
                                                                : ''; // Set to empty if no circumstances selected
                                

                                                            // Update the state
                                                            handleChange("special_circumstances", joinedCircumstances);
                                                        }}
                                                        disabled={isDisabled}
                                                    />
                                                );
                                            }) : ""}


                                            {/* 
                                            <Form.Control
                                                as="textarea"
                                                disabled={isDisabled}
                                                value={applicationDetail?.special_circumstances ?? ""}
                                                onChange={(e) => {
                                                    handleChange("special_circumstances", e.target.value);
                                                }}
                                            ></Form.Control>
                                            
                                            */}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
};

export default PersonalData;
