import { Button, Modal } from "@govtechsg/sgds-react";

export default function CommonModal({
  title = "",
  description = "",
  show,
  handleClose,
  action,
  submitButtonText = "Proceed",
}) {
  return (
    <Modal size={"md"} show={show} onHide={handleClose} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer className={`gap-2`}>
        <Button variant="outline-dark" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={action}>
          {submitButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
