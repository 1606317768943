import { apiCall } from "@/utils/apiCall";
// {
//     "INSTITUTE_ID": "N1",
//     "SCHEME_ID": "NYP",
//     "INSTITUTION": "NYP SG",
//     "YEAR_OFFER": 2024,
//     "MONTH_OFFER": 12,
//     "SERIAL_NO": "np"
// }

export async function getSchemeList(params = {}) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/schemes?limit=0`,
    params,
    "GET"
  );
}

export function postScheme(serialData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/schemes`,
    serialData,
    "POST"
  );
}

export function putScheme(serialID, serialData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/schemes/${serialID}`,
    serialData,
    "PUT"
  );
}

export function deleteScheme(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/schemes/${selectedId}`,
    {},
    "DELETE"
  );
}
