import { apiCall } from "@/utils/apiCall";
import {createUrlWithParams} from "@/components/utils/utils";

export async function getApplicationHomes(ACAD_YR, EXERCISE, ADM_NO) {
  let params = {};
  if (ACAD_YR !== null && ACAD_YR !== undefined) {
    params = {
      ...params,
      acad_yr: ACAD_YR,
    };
  }
  if (EXERCISE !== null && EXERCISE !== undefined) {
    params = {
      ...params,
      exercise: EXERCISE,
    };
  }

  if (ADM_NO !== null && ADM_NO !== undefined) {
    params = {
      ...params,
      adm_no: ADM_NO,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/homes/online`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function getMTCRequest(ACAD_YR, EXERCISE, ADM_NO) {
  let params = {};
  if (ACAD_YR !== null && ACAD_YR !== undefined) {
    params = {
      ...params,
      acad_yr: ACAD_YR,
    };
  }
  if (EXERCISE !== null && EXERCISE !== undefined) {
    params = {
      ...params,
      faas_exercise: EXERCISE,
    };
  }

  if (ADM_NO !== null && ADM_NO !== undefined) {
    params = {
      ...params,
      adm_no: ADM_NO,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/mt/requests`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function getMTCRequestResult(REQUEST_ID, MEAN_TEST_ID) {
  let params = {};
  if (REQUEST_ID !== null && REQUEST_ID !== undefined) {
    params = {
      ...params,
      request_id: REQUEST_ID,
    };
  }
  if (MEAN_TEST_ID !== null && MEAN_TEST_ID !== undefined) {
    params = {
      ...params,
      means_test_id: MEAN_TEST_ID,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/mt/requests/results`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function getMTCases(MEANS_TEST_ID, CASE_ID) {
  let params = {};
  if (CASE_ID !== null && CASE_ID !== undefined) {
    params = {
      ...params,
      case_id: CASE_ID,
    };
  }
  if (MEANS_TEST_ID !== null && MEANS_TEST_ID !== undefined) {
    params = {
      ...params,
      means_test_id: MEANS_TEST_ID,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/mt/cases`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function getMTCaseNotes(MEANS_TEST_ID, CASE_ID) {
  let params = {};
  if (CASE_ID !== null && CASE_ID !== undefined) {
    params = {
      ...params,
      case_id: CASE_ID,
    };
  }
  if (MEANS_TEST_ID !== null && MEANS_TEST_ID !== undefined) {
    params = {
      ...params,
      means_test_id: MEANS_TEST_ID,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/mt/cases/notes`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function getMTCDetails(
  ACAD_YR,
  EXERCISE,
  ADM_NO,
  DETAIL_TAG
) {
  let params = {};
  if (ACAD_YR !== null && ACAD_YR !== undefined) {
    params = {
      ...params,
      acad_yr: ACAD_YR,
    };
  }
  if (EXERCISE !== null && EXERCISE !== undefined) {
    params = {
      ...params,
      faas_exercise: EXERCISE,
    };
  }
  if (ADM_NO !== null && ADM_NO !== undefined) {
    params = {
      ...params,
      adm_no: ADM_NO,
    };
  }
  if (DETAIL_TAG !== null && DETAIL_TAG !== undefined) {
    params = {
      ...params,
      detail_tag: DETAIL_TAG,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/mtc/details`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function postApplicationHomes(data) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications`,
    data,
    "PATCH"
  );
}

export async function getMtConsent(ACK_NO, NYP_CONSENT_ID, DETAIL_TAG, HH_UIN) {
  let params = {};
  if (ACK_NO !== null && ACK_NO !== undefined) {
    params = {
      ...params,
      ack_no: ACK_NO,
    };
  }
  if (NYP_CONSENT_ID !== null && NYP_CONSENT_ID !== undefined) {
    params = {
      ...params,
      nyp_consent_id: NYP_CONSENT_ID,
    };
  }
  if (DETAIL_TAG !== null && DETAIL_TAG !== undefined) {
    params = {
      ...params,
      detail_tag: DETAIL_TAG,
    };
  }
  if (HH_UIN !== null && HH_UIN !== undefined) {
    params = {
      ...params,
      hh_uin: HH_UIN,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/mt/consents`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function getMtIncomes(MEANS_TEST_ID) {
  let params = {};
  if (MEANS_TEST_ID !== null && MEANS_TEST_ID !== undefined) {
    params = {
      ...params,
      means_test_id: MEANS_TEST_ID,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/applications/mt/incomes`;
  const url = createUrlWithParams(baseUrl, params);

  return apiCall(url, {}, "GET");
}

export async function postAppeal(data) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/mtc/details`,
    { data: data },
    "POST"
  );
}

export function postQueryHomesRhhMtc(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_HOMES_URL}/homes/mtc/rhh`,
    jsonData,
    "POST"
  );
}
