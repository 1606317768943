import { apiCall } from "@/utils/apiCall";
import { createUrlWithParams } from "@/components/utils/utils";


export async function getDataUnmatch(
    ACAD_YR = "",
    REC_AWARD_TYPE = "",
    REC_AWARD_CODE = ""
) {
    let params = {};
    if (ACAD_YR !== null && ACAD_YR !== undefined) {
        params = {
            ...params,
            acad_yr: ACAD_YR,
        };
    }
    if (REC_AWARD_TYPE !== null && REC_AWARD_TYPE !== undefined) {
        params = {
            ...params,
            rec_award_type: REC_AWARD_TYPE,
        };
    }
    if (REC_AWARD_CODE !== null && REC_AWARD_CODE !== undefined) {
        params = {
            ...params,
            rec_award_code: REC_AWARD_CODE,
        };
    }

    let baseUrl = process.env.REACT_APP_API_URL + `/applications/postals/unmatch`;
    const url = createUrlWithParams(baseUrl, params);
    return apiCall(
      url,
      {},
      "GET"
    );
}
